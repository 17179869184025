<template>
  <LoadingSplash v-show="loading" />
  <div class="mt-2 bg-white dark:bg-gray-800 p-5 w-full rounded-md box-border border dark:border-gray-700">
    <h2 class="font-bold text-lg text-gray-800 dark:text-gray-200">
      Matr&iacute;culas
    </h2>
    <div class="mt-10 mb-5 relative" ref="plateHeader">
      <div class="p-2 sticky top-0">
        <div class="grid grid-cols-3 gap-3">
          <div class="">
            <label class="block dark:text-gray-200 pr-3">Poblaci&oacute;n</label>
            <select v-model="groupId"
              class="w-full dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400">
              <option value="*">Todos las poblaciones</option>
              <option v-for="(item, id) in groups" :key="id" :value="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="col-span-2">
            <br/>
            <div class="inline-flex relative">
              <vue-tailwind-datepicker @click="showSep = true" :i18n="es - ES" v-model="datePeriodValue" 
                :formatter="formatter" :shortcuts="datePickerOptions" />              
                <Timepicker @time-change="(t) => startTime = t" class="inline-flex"/>
                <Timepicker @time-change="(t) => endTime = t" class="inline-flex" />              
              
            </div>
            <button @click="searchClick"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
              Buscar
            </button>
          </div>          
          <div>
            <br/>
            
          </div>
        </div>
      </div>
      <div class="inline-flex w-full relative">
        <div style="margin-left: 10px;"
          class="input-box border dark:bg-gray-900 lg:ml-0 dark:border-gray-700 rounded-md hidden lg:w-search box-border lg:flex md:flex focus-within:bg-gray-100 dark:focus-within:bg-gray-700">
          <input type="text" placeholder="Introduzca aqui los filtros" @keypress="doSearach" ref="clearSearchBox"
            class="p-3 w-full bg-white dark:bg-gray-900 dark:text-gray-400 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700" />
          <span class="text-3xl p-2 text-gray-400" @click="clearSearch">
            <Icon icon="ic:sharp-clear" />
          </span>
        </div>
        <button
          @click.prevent="if (clearSearchBox.value != '') { filters.push(clearSearchBox.value) }; clearSearchBox.value = '';"
          class="ml-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
          Crear filtro</button>
        <div class="float-right m-3" style="position: absolute; right: 3px; top: -25px;">
          <div class="inline-block" v-show="pages > 0">
            <p class="text-sm dark:text-gray-500 text-gray-400">P&aacute;gina</p>
            <select class="block dark:bg-gray-800 dark:hover:bg-gray-700 
                       border s-10
                       dark:border-gray-700 
                       max-w-lg px-4 py-2 rounded-md text-gray-500 dark:text-gray-400" v-model="currentPage"
              @change="pageChange()">
              <option v-for="n in pages" :value="n">
                {{ n }}
              </option>
            </select>
          </div>
          <div class="inline-block">
            <p class="text-sm dark:text-gray-500 text-gray-400">Resultados por pagina</p>
            <select v-model="pageResults" class="dark:bg-gray-800 dark:hover:bg-gray-700                        
                       border 
                       dark:border-gray-700 
                       max-w-lg px-4 py-2 rounded-md text-gray-500 dark:text-gray-400">
              <option value="10">10</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="200">200</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
          </div>
          <div class="inline-block">
            <span v-show="rowCounter > 0" class="dark:text-gray-200 pr-3"> registros: {{ rowCounter }} </span>
            <span v-show="countResult > 0" class="dark:text-gray-200 pr-3"> total: {{ countResult }} </span>
          </div>

        </div>
      </div>
      <div v-show="filters.length > 0"
        class="mt-5 dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400"
        style="min-width: 100%;">
        <span class="dark:text-gray-200 pr-3">Filtros: </span>
        <span v-for="(item, idx) in filters"
          class="bg-green-600 rounded-full py-1 px-2 mr-2 text-white inline-flex items-center justify-center">{{ item }}
          <a href="#" @click.prevent="removeFilter(idx)">
            <Icon class="ml-1" icon="mdi:clear-circle" />
          </a>
        </span>
      </div>
      <div v-if="showSep == true" style="height: 300px">
      </div>
    </div>
    <perfect-scrollbar class="h-screen">
      <div class="wrapping-table mb-5">
        <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
            <tr>
              <th>
                Num.
              </th>
              <th scope="col" class="uppercase px-6 py-3 w-12 sortable" @click="orderTable('group')">
                Poblaci&oacute;n
              </th>
              <th scope="col" class="uppercase px-6 py-3 w-12 sortable" @click="orderTable('plate')">
                Matr&iacute;cula
              </th>
              <th @click="orderTable('date')" class="sortable">
                Fecha/hora
              </th>
              <th @click="orderTable('camera')" class="sortable">C&aacute;mara</th>
              <th>Imagen</th>
              <th>Tipo</th>
              <th>Direcci&oacute;n</th>
              <th scope="col" class="uppercase px-6 py-3">
                Categor&iacute;a
              </th>
              <th class="w-12"><!-- button edit --></th>
              <th class="w-12"><!-- button show --></th>
            </tr>
          </thead>
          <tbody>
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
              v-for="(items, idx) in plates" :key="items.trackId">
              <td class="px-1 py-4 " @click.prevent="console.log(filterResult(items))">
                {{ idx + ((currentPage - 1) * pageResults) + 1 }}
              </td>
              <td class="px-6 py-4 ">
                {{ items.groupName }}
              </td>
              <td class="px-6 py-4 ">
                {{ items.plate }}
              </td>
              <td>
                {{ new Date(items.timestamp).toLocaleDateString() }}
                &nbsp;&nbsp;
                {{ items.timestamp.toString().substring(11, 23) }}
              </td>
              <td>{{ items.camera.name }}</td>
              <td>
                <a href="#" @click.prevent="showImage(items.trackId, items.plate, items.timestamp, items.camera.name)">
                  <img :src="getPlateImage(items.trackId)" :data-img="items.trackId" style="max-width: 320px ;" />
                </a>
              </td>
              <td>
                <span v-if="items.details.vehicle == 'Truck'">
                  <Icon icon="bxs:truck" style="font-size: 32px;" />
                  Cami&oacute;n
                </span>
                <span v-else-if="items.details.vehicle == 'Car'">
                  <Icon icon="bxs:car" style="font-size: 32px;" />
                  Coche
                </span>
                <span v-else-if="items.details.vehicle == 'Bike'">
                  <Icon icon="ri:motorbike-fill" style="font-size: 32px;" />
                  Moto
                </span>
                <span v-else>
                  <Icon icon="tdesign:vehicle" style="font-size: 32px;" />
                  Veh&iacute;culo
                </span>
              </td>
              <td>
                <div class="text-center">
                  <Icon v-if="items.details.direction == 'Incoming'" icon="material-symbols:input-circle-rounded"
                    style="font-size: 32px;" />
                  <Icon v-else icon="material-symbols:output-circle-rounded" style="font-size: 32px;" />
                </div>
              </td>
              <td class="px-6 py-4">
                <div v-if="items.categories.length > 0">
                  <span v-for="cat in items.categories" :key="cat.id" class="rounded-md py-1 px-2 text-white"
                    :data-plate-cat="items.id" :class="levelMarkerClass(cat.level)">
                    {{ cat.name }}
                  </span>
                </div>
                <span v-show="items.categories.length == 0"
                  class="text-gray-900 bg-white border border-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600   ">
                  Sin categoria
                </span>
              </td>
              <td>
                <button
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  @click="showUpdatePlateCategory(items.groupId, items.id, items.categories, items.plate)">
                  <Icon icon="teenyicons:edit-1-solid" />
                </button>
              </td>
              <td>
                <button
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  @click="showPlateTrack(items.groupId, items.id, items.plate, items.trackId)">
                  <Icon icon="ooui:map-pin" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </perfect-scrollbar>
  </div>

  <Modal v-show="false" btnIcon="mingcute:edit-fill" ref="modalUpdate" btn-text="" btn-text-submit="Actualizar"
    :title="modalUpdateTitle" @submit="updatePlateCategory(updateGroupId, updatePlateId)">
    <template v-slot:body>
      <div
        class="dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400">
        <div>
          <ul>
            <li v-for="(item, id) in categories" :value="item.id">
              <input type="checkbox" :value="id" @change="toogleCat(item.id)"
                :checked="modalUpdCategories.find(el => el.id == item.id && el.selected)" />
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </template>
  </Modal>
  <Modal v-show="false" 
    btnIcon="ri:map-pin-fill" 
    btn-text="" width="w-3/5" 
    ref="mapModalTrakcer"
    @submit="closeTrackModal()" :title="mapTrackerTitle" btn-text-submit="Ok">
    <template v-slot:body>
      <Mapmaker :clear-data="clearTrackData"
       :markers="plateTrackInfo" :lastLocation="lastLocation" :zoom-level="21"/>
    </template>
  </Modal>
  <Modal v-show="false" ref="imageViewer" @submit="imageViewer.isOpen = false" btn-text-submit="Ok" width="max-w-full"
    :title="imageTrackTitle">
    <template v-slot:body>
      <img :src="'data:image/jpeg;base64,' + vehicleImage" class="center-img">
    </template>
  </Modal>
</template>
<script setup>
import { onMounted, ref, watch, } from 'vue';
import { useGroupStore } from '@/store/group'
import { usePlateStore } from "@/store/plate"
import { useCategoryStore } from '@/store/category'
import { Icon } from '@iconify/vue';
import Mapmaker from '@/components/mapmaker.vue';
import LoadingSplash from '@/components/loadingSplash.vue'
import Modal from '@/components/modal.vue'
import VueTailwindDatepicker from "vue-tailwind-datepicker"
import { useImageStore } from '@/store/image';
import { useAuthStore } from '@/store/auth';
import Timepicker from '@/components/Timepicker.vue';

const imageTrackTitle = ref('')
const mapTrackerTitle = ref('')
const pageResults = ref(100)
const vehicleImage = ref('')
const imageViewer = ref()
const showSep = ref(false)
const rowCounter = ref(0)
const clearTrackData = ref(true)

const startTime = ref('')
const endTime = ref('')

const groups = ref([])
const groupId = ref('')
const plates = ref([])
const categories = ref([])
const loading = ref(false)
const clearSearchBox = ref(null)
const mapModalTrakcer = ref(null)

const plateTrackInfo = ref([])
const modalUpdate = ref()
const modalUpdateTitle = ref('')
const updatePlateId = ref('')
const datePeriodValue = ref([])
const currentPage = ref(1)
const countResult = ref(0)
const pages = ref([])
const lastLocation = ref({})
const updateGroupId = ref('')
let ascDesc = 1
let lastOrderColumn = ''
const filters = ref([])
const modalUpdCategories = ref([])
const toogleCat = (i) => {
  modalUpdCategories.value.forEach(element => {
    if (i == element.id) {
      element.selected = !element.selected
    }
  })  
}
const orderTable = (sorter) => {
  if (!sorter || sorter === '') return;
  if (lastOrderColumn == sorter) {
    ascDesc = ascDesc * -1
  }
  else {
    lastOrderColumn = sorter
    ascDesc = 1
  }
  loading.value = true
  let aSort = plates.value.slice()
  switch (sorter) {
    case 'group':
      aSort.sort((a, b) => {
        if (a.groupName.toUpperCase() > b.groupName.toUpperCase()) return 1 * ascDesc
        if (a.groupName.toUpperCase() < b.groupName.toUpperCase()) return -1 * ascDesc
        return 0
      })
      break
    case 'plate':
      aSort.sort((a, b) => {
        if (a.plate.toUpperCase() > b.plate.toUpperCase()) return 1 * ascDesc
        if (a.plate.toUpperCase() < b.plate.toUpperCase()) return -1 * ascDesc
        return 0
      })
      break
    case 'camera':
      aSort.sort((a, b) => {
        if (a.camera.name.toUpperCase() > b.camera.name.toUpperCase()) return 1 * ascDesc
        if (a.camera.name.toUpperCase() < b.camera.name.toUpperCase()) return -1 * ascDesc
        return 0
      })
      break;
    case 'date':
      aSort.sort((a, b) => {
        if (a.timestamp > b.timestamp) return 1 * ascDesc
        if (a.timestamp < b.timestamp) return -1 * ascDesc
        return 0
      })
      break;
  }
  plates.value = aSort
  loading.value = false
}
const datePickerOptions = () => {
  return [
    {
      label: "Hoy",
      atClick: () => {
        const today = new Date(Date.now());
        const date1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)
        const date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)
        return [date1, date2]

      }
    },
    {
      label: "Ayer",
      atClick: () => {
        let today = new Date(Date.now());
        today.setDate(today.getDate() - 1)
        const date1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)
        const date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)
        return [date1, date2]
      }
    },
    {
      label: "Una semana atras",
      atClick: () => dateRange(7)
    },
    {
      label: "30 dias atras",
      atClick: () => dateRange(30)
    }
  ]
}
const formatter = ref({
  date: "DD/MM/YYYY",
  month: "MMM",
});
const dateRange = (days) => {
  const today = new Date(Date.now());
  const date1 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0, 0)
  if (days > 0) {
    today.setDate(today.getDate() - days)
  }
  const date2 = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59, 999)
  return [date2, date1]

}
const showPlateTrack = async (gId, pId, plateValue, trackId) => {  
  const plateStore = usePlateStore()
  let period = datesToArray()

  const trackResponse = period.length == 0  
   ? await plateStore.getPlateTrack(gId, pId)
   : await plateStore.getPlateTrack(gId, pId, period[0], period[1])
  mapTrackerTitle.value = `Rastreo de matricula: ${plateValue}`
  if (trackResponse) {    
    plateTrackInfo.value = trackResponse.sort((a, b) => {
        if (new Date(a.logDate) > new Date(b.logDate)) return -1
        if (new Date(a.logDate) < new Date(b.logDate)) return 1
        return 0
    })
    lastLocation.value = trackResponse[trackResponse.length - 1].location
    clearTrackData.value = false
    mapModalTrakcer.value.isOpen = true
  }
}
const pageChange = () => {
  getPlates()
  ascDesc = -1 * ascDesc
  
  orderTable(lastOrderColumn)
}
const searchClick = () => {
  lastOrderColumn = ''
  ascDesc = 1
  currentPage.value = 1
  getPlates()
}
const closeTrackModal = () => {
  
  mapModalTrakcer.value.isOpen = false 
  clearTrackData.value = true
}
const showUpdatePlateCategory = async (gId, pId, plateCatories, plateValue) => {
  const categoryStore = useCategoryStore()
  const categoryResponse = await categoryStore.getCategories(gId)
  updateGroupId.value = gId
  updatePlateId.value = pId

  categories.value = []
  
  if (categoryResponse !== false) {
    categories.value = categoryResponse
    modalUpdCategories.value = []
    categories.value.forEach(element => {
      modalUpdCategories.value.push({
        id: element.id,
        selected: plateCatories.find(pEl => pEl.id == element.id) != null
      })
    })
  }
  modalUpdateTitle.value = "Categorias para [ " + plateValue + " ]"
  modalUpdate.value.isOpen = true
}

const removeFilter = (idx) => {
  filters.value.splice(idx, 1)
  let total = 0
  plates.value.forEach(element => {
    if (filterResult(element)) total++
  })
  rowCounter.value = total
}
const doSearach = (event) => {
  if (event.key == "Enter") {
    loading.value = true
    loading.value = false
    if (event.target.value != '') {
      filters.value.push(event.target.value)
    }
    event.target.value = ''
    let total = 0
    plates.value.forEach(element => {
      if (filterResult(element)) total++
    })
    rowCounter.value = total
    getPlates()
  }
}

const filterResult = (item) => {
  // not used for now
  if (filters.value.length == 0) { return true }

  let result = []
  filters.value.forEach(element => {
    let innerResult = false
    if (item.plate.toUpperCase().includes(element.toUpperCase())) { innerResult = true }
    if (item.groupName.toUpperCase().includes(element.toUpperCase())) { innerResult = true }
    if (item.camera.name.toUpperCase().includes(element.toUpperCase())) { innerResult = true }
    let vehicleType = ''
    switch (item.details.vehicle) {
      case 'Bike': vehicleType = 'moto'; break;
      case 'Truck': vehicleType = 'camion'; break;
      case 'Car': vehicleType = 'coche'; break;
      case 'Vehicle': vehicleType = 'vehiculo'; break;
    }
    if (vehicleType.toUpperCase().includes(element.toUpperCase())) { innerResult = true }
    item.categories
      .forEach(cat => {
        if (cat.name.toUpperCase().includes(element.toUpperCase())) { innerResult = true }
      });
    result.push(innerResult)
  });

  for (let i = 0; i < result.length; i++) {
    if (result[i] == false) return false;
  }
  return true;

}
const clearSearch = () => {
  loading.value = true
  loading.value = false
  clearSearchBox.value.value = '';
  filters.value = []
  rowCounter.value = plates.value.length
}
const updatePlateCategory = async (gId, pId) => {
  const plateStore = usePlateStore()
  try {
    let updCats = []
    
    modalUpdCategories.value.forEach(element => {
      if (element.selected) {
        updCats.push(element.id)
      }
    })
    
    const response = await plateStore.updatePlateCategory(gId, pId, updCats);
    if (response) {
      modalUpdate.value.isOpen = false
    }
  }
  catch (exception) {
    console.log(exception)
  }
}
const showImage = async (tId, plate, timestamp, cameraName) => {
  const imageStore = useImageStore()
  const response = await imageStore.getVehicleImage(tId)
  if (response) {
    vehicleImage.value = response.image
    const date = new Date(timestamp)
    const dateStr = date.toLocaleDateString() + " " +
      date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + "." + date.getMilliseconds()
    imageTrackTitle.value = `Matricula: ${plate} - Hora:${dateStr} - Camara: ${cameraName}`
    imageViewer.value.isOpen = true
  }

}
const getPlates = async () => {
  loading.value = true
  plates.value = []
  if (groupId.value != '') {
    try {
      const categoryStore = useCategoryStore()
      const categoryResponse = await categoryStore.getAllCategories()
      categories.value = categoryResponse ? categoryResponse : []
      const plateStore = usePlateStore()
      let period = datesToArray()
      showSep.value = false
      const groupFilter = groupId.value == "*" ? null : [groupId.value]
      const plateResponse = period.length > 0
        ? await plateStore.getAllTracks(groupFilter, pageResults.value, currentPage.value, filters.value.slice(), period[0], period[1])
        : await plateStore.getAllTracks(groupFilter, pageResults.value, currentPage.value, filters.value.slice())
      plates.value = plateResponse.resuts ? plateResponse.resuts : []
      pages.value = plateResponse.pageCount
      countResult.value = plateResponse.resultCount
    }
    catch (exception) {
      console.log(exception)
    }
  }
  loading.value = false
}
const getPlateImage = (plateTrackId) => {
  const imageStore = useImageStore()
  imageStore.getPlateImage(plateTrackId).then(response => {
    let el = document.querySelector('img[data-img="' + plateTrackId + '"]')
    el.src = 'data:image/jpeg;base64,' + response.image
  })
}

const levelMarkerClass = (lev) => {
  switch (lev) {
    case 0: return 'bg-green-600';
    case 1: return 'bg-blue-600';
    case 2: return 'bg-yellow-600';
    case 3: return 'bg-orange-600';
    case 4: return 'bg-red-600';
    case 5: return 'bg-black-600';
  }
}
const datesToArray = () => {
  let period = []
  if (datePeriodValue.value.length > 0) {
    var date1 = datePeriodValue.value[0].split("/")
    var date2 = datePeriodValue.value[1].split("/")
    var startDate = new Date(parseInt(date1[2]), parseInt(date1[1]) - 1, parseInt(date1[0]), 0, 0, 0, 0)
    var endDate = new Date(parseInt(date1[2]), parseInt(date1[1]) - 1, parseInt(date1[0]), 23, 59, 59, 999)
    if (startTime.value != '') {
      startDate = new Date(parseInt(date1[2]),
        parseInt(date1[1]) - 1,
        parseInt(date1[0]),
        parseInt(startTime.value.substring(0, 2)),
        parseInt(startTime.value.substring(3, 5)), 0, 0)
    }
    if (endTime.value != '') {          
      endDate = new Date(parseInt(date2[2]),
        parseInt(date2[1]) - 1,
        parseInt(date2[0]),
        parseInt(endTime.value.substring(0, 2)),
        parseInt(endTime.value.substring(3, 5)), 59, 999)
    }
    period.push(startDate)
    period.push(endDate)
  }
  return period
}
watch(plates, (nPlates, oPlates) => {
  rowCounter.value = nPlates.length

})

watch(datePeriodValue, () => {
  showSep.value = false
}, { immediate: true })


onMounted(async () => {
  const authStore = useAuthStore()
  const groupStore = useGroupStore()
  const groupResponse = authStore.isAdmin ? await groupStore.getGroups() : await groupStore.getUserGroups()
  groups.value = groupResponse ? groupResponse : []
})
</script>
<style scoped>
tbody tr:hover {
  background-color: aqua;
}

.sortable:hover {
  text-decoration: underline;
  cursor: pointer;
}

.center-img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%;
  max-height: 55%;
}
.s-10 {
  min-width: 4rem;
}
</style>