<template>
  <div class="mt-2 lg:flex block lg:gap-2 relative ml-2">
    <div class="bg-white dark:bg-gray-800 p-0 lg:w-3/4 w-full rounded-md box-border border dark:border-gray-700">
      <div class="p-5 justify-between h-full">
        <div>
          <h2 class="font-semibold text-2xl text-gray-800 dark:text-gray-200">
            Mapa de camaras
          </h2>
          <p class="text-gray-400 font-lexend font-normal">
            Posicion de las camaras
          </p>
        </div>
        <div class="wrapper-button w-full box-border mt-4 h-full">
          <GMapMap :center="location" :zoom="9" map-type-id="terrain" ref="cameraMap"
            v-show="markers.length > 0"
            style="width: 100%; min-height: 220px; height: 50rem;" :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: true,
              streetViewControl: false,
              rotateControl: true,
              fullscreenControl: false,
            }">
            <GMapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :icon="{
              url: require('@/assets/map-marker.svg'),
              scaledSize: { width: 50, height: 50 },
              labelOrigin: { x: 16, y: -10 }
            }" />
          </GMapMap>
        </div>
      </div>
      <br />
    </div>
    <div class="bg-white dark:bg-gray-800 p-5 lg:mt-0 mt-4 lg:w-2/4 border dark:border-gray-700 rounded-md w-full">
      <div class="">
        <h2 class="text-lg font-semibold dark:text-gray-200 inline-flex">
          Lista de camaras
        </h2>
        <div>
          <div>
            <label class="dark:text-gray-200">Poblaci&oacute;n</label>
            <select v-model="groupId"
              class="dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400"
              @click="getServers">
              <option v-for="(item, id) in groups" :key="id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div>
            <label class="dark:text-gray-200">Servidor</label>
            <select v-model="serverId" @change="getRemoteCameras"
              class="dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400">
              <option v-for="(item, id) in servers" :key="id" :value="item.id">
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>

        <div style="position: absolute; right: 1.5rem; top:1.5rem;">
          <Modal v-show="serverId" btnText="Añadir desde Nx" ref="modalCamera" width="30vw" :title="modalTitle"
            @click="clearModalAdd" @submit="postCameraChanges" btnIcon="ant-design:cloud-server-outlined">
            <template v-slot:body>
              <select v-model="newCamera.id" v-show="newCamera.id == ''"
                class="dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400">
                <option v-for="rCamera in remoteCameras" :key="id" :value="rCamera.id">
                  {{ rCamera.name }}
                </option>
              </select>

              <div class="pb-3">
                <p class="inline-flex pr-3" for="remoteName">Nombre</p>
                <input type="text" name="remoteName" class="p-2 border 
                  dark:border-gray-600 
                  dark:bg-gray-700 rounded outline-none inline-flex" placeholder="nombre" v-model="newCamera.name" />
              </div>
              <div class="pb-5">
                <p class="inline-flex pr-3">Latitud</p>
                <input type="text" name="remoteLat"
                  class="ml-2 w-48 p-2 border dark:border-gray-600 dark:bg-gray-700 rounded outline-none inline-flex"
                  placeholder="remoteLat" v-model="newCamera.lat" />

                <p class="inline-flex pl-3 pr-3">Longitud</p>
                <input type="text"
                  class="ml-2 w-48 p-2 border dark:border-gray-600 dark:bg-gray-700 rounded outline-none inline-flex"
                  name="remoteLat" placeholder="remoteLon" v-model="newCamera.lon" />
              </div>

              <div>
                <GMapMap @click="setCameraPos" style="width: 100%; height: 480px;" ref="cameraMapModal" :zoom="9"
                  map-type-id="terrain" :center="{ lat: 42.51740819186509, lng: -1.7577765377874135 }" :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: true,
                    fullscreenControl: false,
                  }">
                  <GMapMarker v-if="viewMarker" :position="mPosition" :icon="{
                    url: require('@/assets/map-marker.svg'), scaledSize: { width: 50, height: 50 }, labelOrigin: { x: 16, y: -10 }
                  }" />
                </GMapMap>
                <GMapAutocomplete placeholder="This is a placeholder"
                  class="ml-2 w-full p-2 border dark:border-gray-600 dark:bg-gray-700 rounded outline-none inline-flex"
                  @place_changed="setPlace">
                </GMapAutocomplete>
              </div>
            </template>
          </Modal>
        </div>
      </div>
      <perfect-scrollbar class="divide-y h-96 mt-5 dark:divide-gray-700">
        <div class="p-3 w-full" v-for="itemCamera in cameras" :key="id">
          <div class="flex gap-5">
            <div>
              <Icon class="mt-3 dark:text-gray-200" icon="fluent:video-security-24-filled" :height="24" />
            </div>
            <div class="mt-1">
              <h2 class="dark:text-gray-200">
                <a href="#" @click.prevent="showEditCamera(itemCamera.id)">
                  {{ itemCamera.name }}
                </a>
              </h2>
              <p class="text-sm dark:text-gray-500 text-gray-400">
                Active now
              </p>
              <div class="list-button">
                <button @click="cameraIngest(itemCamera.id)"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <Icon icon="humbleicons:download" />
                </button>
                <button @click="deleteCamera(itemCamera.id)"
                  class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                  <Icon icon="mingcute:delete-fill" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>
<script setup>
import { ref, defineProps, onMounted, watch } from 'vue'
import Modal from '@/components/modal.vue'
import { Icon } from "@iconify/vue";
import { useCameraStore } from '@/store/camera';
import { useServerStore } from '@/store/server';
import { useGroupStore } from '@/store/group';
import { usePlateStore } from '@/store/plate';
const isEditing = ref(false)
const cameraMapModal = ref()
const modalTitle = ref('')
const serverId = ref('')
const groupId = ref('')
const servers = ref([])
const groups = ref([])
const modalCamera = ref(null)
const cameras = ref([])
const remoteCameras = ref([])
const cameraMap = ref()
const newCamera = ref({
  serverId: '',
  id: '',
  name: '',
  description: '',
  lon: 0,
  lat: 0
})


const viewMarker = ref(false)
const mPosition = ref({
  lng: 0,
  lat: 0
})
const postCameraChanges = () => {
  if (isEditing.value) {
    updateCamera()
  }
  else {
    addRemoteCam()
  }
}
const setPlace = (autocomplete) => {
  
  viewMarker.value = false
  newCamera.value.lat = autocomplete.geometry.location.lat()
  newCamera.value.lon = autocomplete.geometry.location.lng()

  mPosition.value.lng = autocomplete.geometry.location.lng()
  mPosition.value.lat = autocomplete.geometry.location.lat()
  viewMarker.value = true
}

onMounted(async () => {
  const groupStore = useGroupStore()
  const groupResponse = await groupStore.getGroups()
  groups.value = []
  if (groupResponse != false) {
    groups.value = groupResponse
  }
})
const getServers = async () => {
  const serverStore = useServerStore()  
  const response = await serverStore.getGroupServers(groupId.value)
  servers.value = []
  if (response != false) {
    servers.value = response
  }
}
const cameraIngest = async (cId) => {
  const cameraStore = useCameraStore()
  const result = await cameraStore.ingestCamera(cId)
  return result
}

const fitMapBounds = () => {
  let markersFit = []
  let bounds = new google.maps.LatLngBounds()
  cameras.value.forEach((cam) => {
    markersFit.push({ position: {
        lng: cam.location.lon, lat: cam.location.lat
      }      
    })    
    bounds.extend( {
        lng: cam.location.lon,  lat: cam.location.lat
      })
  })
  markers.value = markersFit 
  if (markersFit.length > 0) {
    cameraMap.value.$mapPromise.then((mapObject) => {
       mapObject.fitBounds(bounds);
    })
  }
}

const getRemoteCameras = async () => {
  if (serverId.value != '') {
    const cameraStore = useCameraStore()
    cameras.value = await cameraStore.getCameras(serverId.value)
    fitMapBounds()
    let remotes = await cameraStore.getRemoteCameras(serverId.value)
    if (remotes && remotes.length > 0) {
      remoteCameras.value = remotes.sort((a, b) => {
        let fa = a.name.toLowerCase()
        let fb = b.name.toLowerCase()
        if (fa < fb) return -1;
        if (fa > fb) return 1;
        return 0;
      })
    }
    else {
      remoteCameras.value = []
    }
  }
}

const setCameraPos = (map) => {
  viewMarker.value = false
  newCamera.value.lat = map.latLng.lat()
  newCamera.value.lon = map.latLng.lng()

  mPosition.value.lng = map.latLng.lng()
  mPosition.value.lat = map.latLng.lat()
  viewMarker.value = true
}
const addRemoteCam = async () => {
  const cameraStore = useCameraStore()
  const response = await cameraStore.addCamera(
    serverId.value,
    newCamera.value.id,
    newCamera.value.name,
    newCamera.value.description,
    newCamera.value.lon,
    newCamera.value.lat)
  if (response) {
    modalCamera.value.isOpen = false
    let remotes = await cameraStore.getRemoteCameras()
    remoteCameras.value = remotes.sort((a, b) => {
      let fa = a.name.toLowerCase()
      let fb = b.name.toLowerCase()
      if (fa < fb) return -1;
      if (fa > fb) return 1;
      return 0;
    })
  }
}
const clearModalAdd = () => {
  viewMarker.value = false
  newCamera.value = {
    serverId: '',
    id: '',
    name: '',
    description: '',
    lon: 0,
    lat: 0
  }
  isEditing.value = false
  modalTitle.value = 'Camaras disponibles'
}
const showEditCamera = async (cId) => {
  const cameraStore = useCameraStore()
  const camera = await cameraStore.getCamera(serverId.value, cId);
  if (camera) {
    newCamera.value = {
      serverId: camera.serverId,
      id: camera.id,
      name: camera.name,
      lon: camera.location.lon,
      lat: camera.location.lat,
      description: camera.description
    }
    viewMarker.value = true
    mPosition.value.lng = camera.location.lon
    mPosition.value.lat = camera.location.lat

    modalTitle.value = 'Editar camara'
    isEditing.value = true

    cameraMapModal.value.center.lng = camera.location.lon
    cameraMapModal.value.center.lat = camera.location.lat

    modalCamera.value.isOpen = true
  }
}

const deleteCamera = async (cId) => {
  const plateStore = usePlateStore()
  const registryCount = await plateStore.getCameraCount(cId)
  if (Number.isInteger(registryCount) && registryCount > 0) {
    alert('Camara con registros. No se puede borrar')
    return
  }

  const cameraStore = useCameraStore()

  const response = await cameraStore.deleteCamera(cId)
  if (response === true) {
    cameras.value = await cameraStore.getCameras(serverId.value)
  }
}
const updateCamera = async () => {
  const cameraStore = useCameraStore()
  const response = await cameraStore.editCamera(
    newCamera.value.id,
    newCamera.value.name,
    newCamera.value.description,
    newCamera.value.lon,
    newCamera.value.lat)
  if (response) {
    modalCamera.value.isOpen = false
    cameras.value = await cameraStore.getCameras(serverId.value)
  }
}


const location = ref({
  lng: -0.7396143617666272,
  lat: 38.25046732205562,
})
const markers = ref([])
watch(groupId, (nGroup, oGroup) => {
  if (nGroup != oGroup) {
    serverId.value = ''
    cameras.value = []
    remoteCameras.value = []
  }
})
</script>
<style scoped>
.rcField {
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  padding: 5px;
  margin: 2px;
}

label {
  width: 3vw;
  display: inline-flex;
}

.list-button {
  position: absolute;
  float: right;
  right: 0;
  margin-top: -50px;

}
</style>
