<template>
    <div class="py-3 w-full">
        <div class="flex gap-5 relative">
            <div>
                <img :src="getGroupImage(id, id)" :data-group-item="id" class="w-14 rounded-md" src="/img/user1.0d4be88d.png" alt="">
            </div>            
            <div class="mt-1">
                <h2 class="dark:text-gray-200">
                    <a class="user-item" href="#" @click.prevent="editGroup">
                        {{ name }}
                    </a>
                </h2>
            </div>
            
            <div class="buttons-container">                
                <button
                    @click="deleteGroup"                  
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    <Icon icon="mingcute:delete-fill"></Icon>
                </button>
                <button 
                    @click="openAddUsers(props.id)"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <Icon icon="mdi:users-add"></Icon>
                </button>
                <button @click="openShowUsers(props.id)"
                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <Icon icon="fa:users"></Icon>
                </button>
            </div>
        </div>        
    </div>
</template>
<script setup>
import { Icon } from '@iconify/vue';
import { useGroupStore } from '@/store/group';
import { inject, onMounted, ref } from 'vue'
import { useImageStore } from '@/store/image';
const { userList, openAddUsers, openShowUsers, modalGroup, groupId, modalTitle, newGroupName, getGroupImage, modalImage } = inject('userList')
const groupStore = useGroupStore()
const props = defineProps({
    id: String,
    name: String,
    image: String
})
const deleteGroup = async () => {
    await groupStore.deleteGroup(props.id)
}
const editGroup = async () => {
    groupId.value = props.id
    modalTitle.value = 'Editar población'
    newGroupName.value = props.name
    const imageStore = useImageStore()
    const image = await imageStore.getGroupImage(props.id)
    if (image) {
        modalImage.value = 'data:image/jpeg;base64, ' + image.image
    }
    else {
        modalImage.value = ''
    }

    
    modalGroup.value.isOpen = true

}

</script>
<style scoped>
.after\:top-0\.5::after {
    top: 1.1rem;
}
.del-button {
    position: relative;
    top: 2px;
    right: 0;
}
.user-item:hover {
    text-decoration: underline;
}
.buttons-container {
    position: absolute; right:30px;
}
</style>