import { defineStore } from "pinia";
import { useAuthStore } from "./auth";
import { uuid } from "vue-uuid";
export const useServerStore = defineStore('server', {
    actions: {
        async getServers() {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const response = await fetch(`${auth.baseUrl}/servers`, request)
            if (response && !response?.ok) {
                return await response.json()
            }
            return false
        },
        async getServer(id) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const response = await fetch(`${auth.baseUrl}/servers/${id}`, request)
            return response.status == 200 ? await response.json() : false
            
        },
        async getGroupServers(groupId) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const response = await fetch(`${auth.baseUrl}/servers/group/${groupId}`, request)
            
            return response.status == 200 ? await response.json() : false
        },
        async addServer(name, params, groupId) {
            const auth = useAuthStore()
            const id = uuid.v1()
            const request = auth.buildRequest('POST', true,
            {
                'id': id,
                'name': name,
                'config': params,
                'apiType': 'Nx',
                'groupId': groupId
            })
            const response = await fetch(`${auth.baseUrl}/servers`, request)
            return response.status == 200 ? id : false
        },
        async deleteServer(serverId) {
            const auth = useAuthStore()
            const request = auth.buildRequest('DELETE', true)
            const response = await fetch(`${auth.baseUrl}/servers/${serverId}`, request)
            return response.status == 200
        },
        async updateServer(id, name, params, groupId) {
            const auth = useAuthStore()            
            const request = auth.buildRequest('PUT', true,
            {
                'id': id,
                'name': name,
                'config': params,
                'apiType': 'Nx',
                'groupId': groupId
            })
            const response = await fetch(`${auth.baseUrl}/servers`, request)
            return response.status == 200
        }
    }
});