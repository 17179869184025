import { defineStore } from "pinia"
import { useAuthStore } from "./auth"
export const useImageStore = defineStore('image', {
    actions: {
        async getPlateImage(id) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const response = await fetch(`${auth.baseUrl}/image/plate/${id}`, request)
            return response.status == 200 ? await response.json() : false
        },
        async getVehicleImage(id) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const response = await fetch(`${auth.baseUrl}/image/vehicle/${id}`, request)
            return response.status == 200 ? await response.json() : false
        },
        async getUserImage(id) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const response = await fetch(`${auth.baseUrl}/image/user/${id}`, request)
            return response.status == 200 ? await response.json() : false
        },
        async getGroupImage(id) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const response = await fetch(`${auth.baseUrl}/image/group/${id}`, request)
            return response.status == 200 ? await response.json() : false
        },
        async saveGroupImage(id, data) {
            if (data.startsWith('data:image')) {
                data = data.replace(/^data:image\/[a-z]+;base64,/, "");
            }            
            const auth = useAuthStore()
            const request = auth.buildRequest('POST', true, data)
            
            const response = await fetch(`${auth.baseUrl}/image/group/${id}`, request)
            return response.status == 200 
        }
    }
})