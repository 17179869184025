import { defineStore } from "pinia"
import { useAuthStore } from "./auth"
import { uuid } from "vue-uuid";
export const usePlateStore = defineStore('plate', {
    actions: {
        async getGroupPlates(groupId, start, end) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true);
            let url = `${auth.baseUrl}/plate/${groupId}`
            let params = ''
            if (start != undefined && start != null) {
                params += '?startTime=' + encodeURI(start)
            }
            if (end != undefined && end != null) {
                params += (params != '' ? "&" : "?") + 'end=' + encodeURI(start)
            }
            const response = await fetch(url, request)
            return response.status == 200 ? await response.json() : false
        },
        async updatePlateCategory(groupId, plateId, categories) {
            const auth = useAuthStore()
            const request = auth.buildRequest('PUT', true, {
                'plateId': plateId,
                'categories': categories
            });
            const response = await fetch(`${auth.baseUrl}/plate/${groupId}`, request)
            return response.status == 200
        },
        async getPlateTrack(groupId, plateId, dateStart, dateEnd) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            let url = `${auth.baseUrl}/plate/${groupId}/trace/${plateId}`
            if (dateStart != null && dateStart != undefined) {
                url += '?startTrack=' + dateStart.toJSON()
            }
            if (dateEnd != null && dateEnd != undefined) {
                url += '&endTrack=' + dateEnd.toJSON()
            }
            const response = await fetch(url, request)
            return response.status == 200 ? await response.json() : false
        },
        async getAllTracks(groupId, pageLimit, page, filter, startDate, endDate) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            
            let url = ''
            if (groupId) {
                groupId.forEach(element => url += (url == '' ? '?' : '&') + 'groupId=' + element)
            }
            if (startDate != undefined && startDate != null) {
                url += (url == '' ? '?' : '&') + 'startTime=' + startDate.toJSON()
            }
            if (endDate != undefined && endDate != null) {
                url += (url == '' ? '?' : '&') + 'endTime=' + endDate.toJSON()
            }
            if (pageLimit) {
                url += (url == '' ? '?' : '&') + 'limit=' + pageLimit
            }
            if (page) {
                url += (url == '' ? '?' : '&') + 'page=' + page
            }
            
            if (filter) {
                filter.forEach(element => {
                    url += (url == '' ? '?' : '&') + 'filter=' + element
                });                
            } 
            

            url = `${auth.baseUrl}/plate/alltraces` + url

            const response = await fetch(url, request)
            return response.status == 200 ? await response.json() : false
        },
        async addPlate(groupId, plateValue, plateCategoryId) {
            const auth = useAuthStore()
            const id = uuid.v1()
            let body = {
                'Id': id,
                'plateValue': plateValue,

            }
            if (plateCategoryId) {
                body.categoryId = plateCategoryId
            }
            const url = `${auth.baseUrl}/plate/${groupId}`
            const request = auth.buildRequest('POST', true, body)
            const response = await fetch(url, request)
            return response.status == 200 ? id : false
        },
        async getCameraCount(id) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const url = `${auth.baseUrl}/plate/registry/${id}`
            const response = await fetch(url, request)
            
            return response.status == 200 ? await response.json() : false
        }
    }
})
