import { defineStore } from "pinia"
export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            bearer: null,
            baseUrl: process.env.VUE_APP_API_URL,
            userId: '',
            userName: '',
            userMail: '',
            isAdmin: false
        }
    },
    getters: {
        token: (state) => state.bearer,
        isAuth: (state) => state.bearer != null
    },
    actions: {
        buildRequest(method, needAuth = true, body = null) {
            const request = {
                'method': method,
                'headers': {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
            if (needAuth) {
                request.headers.Authorization = 'Bearer ' + this.bearer
            }

            if (body != null) {
                request.body = typeof body == 'object' ? JSON.stringify(body) : `"${body}"`
            }

            return request
        },
        async auth(user, password) {
            const request = this.buildRequest('POST', false, {
                'username': user,
                'password': password
            })
            const uri = `${this.baseUrl}/auth`

            const rawResponse = await fetch(uri, request)
            if (rawResponse.status == 200) {
                const response = await rawResponse.json()
                this.bearer = response.accessToken
                this.userId = response.userId
                this.userMail = user
                this.userName = response.userName
                this.isAdmin = response.isAdmin
                return true
            }
            else {
                this.bearer = null
                return false
            }
        },
        close() {
            this.bearer = null
            this.userId = ''
            this.userMail = ''
            this.isAdmin = false
            this.userName = ''
        }
    }
})