import { defineStore } from "pinia"
import { useAuthStore } from "./auth"
export const useStatsStore = defineStore('stats', {
    actions: {
        async getDailyCaptures(day, is24h) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            let url = `${auth.baseUrl}/stats/hourcount`
            if (day) {
                url += '?date=' + day
            }
            if (is24h === true) {
                url += '&is24h=true'
            }
            const response = await fetch(url, request)
            return response.status == 200 ? await response.json() : false
        },
        async getWeekCaptures(day) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            let url = `${auth.baseUrl}/stats/weekcount`
            if (day) {
                url += '?date=' + day
            }
            const response = await fetch(url, request)
            return response.status == 200 ? await response.json() : false
        },
        async getMountCount(year, month) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const url = `${auth.baseUrl}/stats/monthcount/${year}/${month}`
            const response = await fetch(url, request)

            return response.status == 200 ? await response.json() : false
        },
        async getDayCount(year, month, day) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const url = `${auth.baseUrl}/stats/daycount/${year}/${month}/${day}`
            const response = await fetch(url, request)

            return response.status == 200 ? await response.json() : false
        },
        async getCategoryStats(start, end) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const url = `${auth.baseUrl}/stats/categories/${start}/${end}`
            const response = await fetch(url, request)

            return response.status == 200 ? await response.json() : false
        },
        async getDaysBefore(date, days) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            let url = `${auth.baseUrl}/stats/daysbefore/`
            url += `?date=${date}&days=${days}`
            const response = await fetch(url, request)

            return response.status == 200 ? await response.json() : false
        }
    }
})