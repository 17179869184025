<template>
    <div style="width: 150px;">
        <div class="file-box border-dotted border-2 border-sky-500 border rounded rounde-lg rounded-lg" @drop.prevent="alert('hola')">            
            <img :src="props.showImage" class="img-fit" />
        </div>
        <button @click.prevent="inputImg.click()" class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
            Subir
            <Icon class="ml-2" icon="el:upload" />
        </button>
        <input v-show="false"  type="file"  ref="inputImg"         
          @change.prevent="imgAvatar">
    </div>
</template>
<script setup>
import { Icon } from '@iconify/vue';
import { ref, defineProps, onMounted } from 'vue'
const inputImg = ref()

const emit = defineEmits(['changeImage'])
const props = defineProps({
    showImage: String
})
// const showImage = ref('')

const imgAvatar = async (event)  => {
    let file = event.target.files[0]
    if (file) {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file)
        fileReader.addEventListener("load", function () {
            props.showImage = this.result
            emit('changeImage', this.result)
        })
    }    
}

onMounted(() => {
    // showImage.value =''
    // if (props.showImage) {
    //     showImage.value = props.showImage
    // }
})
</script>
<style>
.file-box {
    height: 150px;
    width: 150px;
}
.img-fit {
    max-width: 100%;
    max-height: 150px;
}
</style>