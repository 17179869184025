import { createRouter, createWebHistory } from "vue-router";

// Component Pages
import Valert from "../views/components/alert.vue";
import Vaccrodion from "../views/components/accordion.vue";
import Vbadges from "../views/components/badges.vue";
import Vbreadcumb from "../views/components/breadcumbs.vue";
import Vbutton from "../views/components/button.vue";
import Vcard from "../views/components/card.vue";
import Vdropdown from "../views/components/dropdown.vue";
import Vmodal from "../views/components/modal.vue";
import Login from "../views/layouts/auth/Login.vue";
import Register from "../views/layouts/auth/Register.vue";
import ForgotPassword from "../views/layouts/auth/forgot-password.vue";
import Dashboard2 from "@/views/Dashboard2.vue"
import Alerts from "@/views/alerts.vue"
// layouts
import Blank from "../views/layouts/Blank.vue";

// error page
import Page404 from "../views/layouts/error/404.vue";
import Page500 from "../views/layouts/error/500.vue";
import PageMaintenance from "../views/layouts/error/maintenance.vue";
import Tables from "../views/tables.vue";

import Cameras from "../views/Cameras.vue";
import Users from "@/views/Users.vue";
import Plates from "@/views/Plates.vue";
import Groups from "@/views/Groups.vue";
import CategoriesView from "@/views/CategoriesView.vue"
import CameraHosts from "@/views/CameraHosts.vue"

import { useAuthStore } from "@/store/auth";

var appname = "N3 Cloud";

const routes = [
  // Routes
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard2,
    meta: { title:appname +  " - Panel" , needAuth: true },
  },

  // Components based Routes
  {
    path: "/component/alert",
    name: "Valert",
    component: Valert,
    meta: { title: "Alert" + appname },
  },
  {
    path: "/component/accordion",
    name: "Vaccordion",
    component: Vaccrodion,
    meta: { title: "Accordion" + appname },
  },
  {
    path: "/component/badge",
    name: "Vbadge",
    component: Vbadges,
    meta: { title: "Badge" + appname },
  },
  {
    path: "/component/breadcumb",
    name: "Vbreadcumb",
    component: Vbreadcumb,
    meta: { title: "Breadcumb" + appname },
  },
  {
    path: "/component/button",
    name: "Vbutton",
    component: Vbutton,
    meta: { title: "Button" + appname },
  },
  {
    path: "/component/card",
    name: "Vcard",
    component: Vcard,
    meta: { title: "Card" + appname },
  },
  {
    path: "/component/dropdown",
    name: "Vdropdown",
    component: Vdropdown,
    meta: { title: "Dropdown" + appname },
  },
  {
    path: "/component/modal",
    name: "Vmodal",
    component: Vmodal,
    meta: { title: "Modal" + appname },
  },
  // layouts

  {
    path: "/Blank",
    name: "Blank Page",
    component: Blank,
    meta: { title: "Blank Page" + appname },
  },

  {
    path: "/auth/login",
    name: "Login",
    component: Login,
    meta: { title: "Acceso " + appname, hideNav: true },
  },
  {
    path: "/auth/register",
    name: "Register",
    component: Register,
    meta: { title: "Registro " + appname, hideNav: true },
  },
  {
    path: "/auth/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    meta: { title: "i Forgot Password" + appname, hideNav: true },
  },
  // layout/error
  // default page 404
  {
    path: "/:pathMatch(.*)*",
    name: "Page404",
    component: Page404,
    meta: { title: "Upps! 404" + appname, hideNav: true },
  },
  {
    path: "/500",
    name: "Page500",
    component: Page500,
    meta: { title: "Server internal Error" + appname, hideNav: true },
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: PageMaintenance,
    meta: {
      title: "Sorry the app has been Maintenance" + appname,
      hideNav: true,
    },
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
    meta: {
      title: "Tables" + appname,
    },
  },
  {
    path :"/cameras",
    name: "Cameras",
    component: Cameras,
    meta : {
      title: appname + " - Camaras",
      needAuth: true
    },
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    meta: {
      title: appname + " - Usuarios" ,
      needAuth: true,
      admin: true
    }
  },
  {
    path: "/plates",
    name: "Plates",
    component: Plates,
    meta: {
      title: appname + " - Busquedas",
      needAuth: true,
      admin: false
    }
  },
  {
    path: "/groups",
    name: "Groups",
    component: Groups,
    meta: {
      title: appname + " - Poblaciones",
      needAuth: true,
      admin: true
    }
  },
  {
    path: '/categories',
    name: 'Categories',
    component: CategoriesView,
    meta: {
      title: appname + " - Categorias",
      needAuth: true,
      admin: false
    }
  },
  {
    path: '/servers',
    name: 'Servers',
    component: CameraHosts,
    meta: {
      title: appname + " - Servidores",
      needAuth: true,
      admin: false
    }
  },
  {
    path: '/alerts',
    name: "Alerts",
    component: Alerts,
    meta: {
      title: appname + " - Alertas",
      needAuth: true,
      admin: false
    }
  }
];

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes,

  linkExactActiveClass: "exact-active",
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore()
  document.title = to.meta.title;
  if (to.meta.needAuth && !auth.isAuth) next({ name: 'Login'});
  else next();
});

export default router;
