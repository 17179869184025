<template>
    <div class="p-3 w-full">
        <div class="flex gap-5">
            <div style="height: 55px;">
                <img class="w-14 rounded-md" :src="userImage" alt="">
            </div>
            <div class="mt-1">
                <h2 class="dark:text-gray-200">
                    <a class="user-item" href="#" @click.prevent="openModal">
                        {{ name }}
                    </a>
                </h2>
                <p class="text-sm dark:text-gray-500 text-gray-400"> {{ email }} </p>
            </div>

            <label class="relative inline-flex items-center cursor-pointer">
                <input type="checkbox" value="" class="sr-only peer" checked>
                <div
                    class="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600">
                </div>

            </label>
        </div>
    </div>    
</template>
<script setup>
import { ref, defineProps, inject, onMounted } from 'vue';
import { useUsersStore } from '@/store/user';

const {newUserEmail, newUserName, userId, newUserPass, modalTitle } = inject('userData')
const props = defineProps({
    id: String,
    name: String,
    email: String,
    selected: Boolean,
    modalUser: Object,
    groups: Array
})

const openModal = () => {
    newUserEmail.value = props.email
    newUserName.value = props.name
    newUserPass.value = ''
    userId.value = props.id
    
    props.modalUser.isOpen = true
    modalTitle.value = 'Editar usuario'
}
const getUserImage = async () => {
    const userStorage = useUsersStore()
    const response = await userStorage.getImageUser(props.id)    
    userImage.value = 'data:image/jpeg;base64,' + response.image
}

const userImage = ref('')
onMounted(() => {
    getUserImage(props.id)    
})
</script>
<style scoped>
.after\:top-0\.5::after {
    top: 1.1rem;
}

.user-item:hover {
    text-decoration: underline;
}
</style>