<template>
  <!-- sidebar -->
  <nav class="sidebar bg-white dark:bg-gray-800">
    <!-- sidebar head -->
    <div class="sidebar-head p-4">
      <router-link to="/" exact class="flex">
        <img class="mt-1" src="@/assets/logo.png" alt="logo Nava3" />
        
      </router-link>
      <div class="bg-gray-700 absolute mt-3 dark:block hidden rounded-md py-1 px-2 text-xs text-gray-200">
        Dark mode
      </div>
      <button class="lg:hidden block dark:text-gray-400 float-right -mt-7" @click="$emit('sidebarToggle')">
        <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="25px" height="25px"
          preserveAspectRatio="xMidYMid meet" viewBox="0 0 32 32">
          <path fill="currentColor"
            d="M7.219 5.781L5.78 7.22L14.563 16L5.78 24.781l1.44 1.439L16 17.437l8.781 8.782l1.438-1.438L17.437 16l8.782-8.781L24.78 5.78L16 14.563z" />
        </svg>
      </button>
    </div>
    <!-- sidebar list -->
    <div class="sidebar-list p-4 mt-4 divide-y dark:divide-gray-700">
      <div class="pb-5">
        <p class="font-medium text-gray-400 dark:text-gray-400">Menu</p>
        <div class="wrap-item mt-4 dark:text-gray-500">
          <div class="item">
            <router-link to="/" exact
              class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700">
              <span class="mr-3 text-xl">
                <Icon icon="ic:round-dashboard" />
              </span>
              <span class="w-full"> Panel </span>
            </router-link>
          </div>          
          <div class="item">
            <router-link to="/plates" exact
              class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700">
              <span class="mr-3 text-xl">
                <Icon icon="material-symbols:picture-in-picture-alt-rounded" />
              </span>
              <span class="w-full">B&uacute;squedas </span>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/categories" exact
              class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700">
              <span class="mr-3 text-xl">
                <Icon icon="mdi:category-plus" />
              </span>
              <span class="w-full"> Categorias </span>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/alerts" exact
              class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700">
              <span class="mr-3 text-xl">
                <Icon icon="ant-design:alert-filled" />
              </span>
              <span class="w-full"> Alertas </span>
            </router-link>
          </div>
        </div>
      </div>
      <div class="pb-5" v-if="auth.isAdmin">
        <p class="font-medium text-gray-400 mt-4 dark:text-gray-400">
          Administrador
        </p>
        <div class="wrap-item mt-4 dark:text-gray-500">
          <div class="item">
            <router-link to="/groups" exact
              class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700">
              <span class="mr-3 text-xl">
                <Icon icon="el:group-alt" />
              </span>
              <span class="w-full"> Poblaciones </span>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/servers" exact
              class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700">
              <span class="mr-3 text-xl">
                <Icon icon="fluent:server-24-filled" />
              </span>
              <span class="w-full"> Servidores </span>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/cameras" exact
              class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700">
              <span class="mr-3 text-xl">
                <Icon icon="mdi:security-camera" />
              </span>
              <span class="w-full"> C&aacute;maras </span>
            </router-link>
          </div>
          <div class="item">
            <router-link to="/users" exact
              class="w-full flex text-left rounded-md box-border p-3 hover:bg-gray-200 dark:hover:bg-gray-700">
              <span class="mr-3 text-xl">
                <Icon icon="fa:users" />
              </span>
              <span class="w-full"> Usuarios </span>
            </router-link>
          </div>
        </div>


      </div>
    </div>
  </nav>
</template>

<script setup>
import { Icon } from "@iconify/vue";
import { useAuthStore } from "@/store/auth";
const auth = useAuthStore()
</script>
