import { defineStore } from "pinia"
import { useAuthStore } from "./auth"
export const useCameraStore = defineStore('camera', {
    actions: {
        async getCamera(serverId, cameraId) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const response = await fetch(`${auth.baseUrl}/cameras/${serverId}/${cameraId}`, request)
            if (response.ok) {
                const result =  await response.json()
                return result 
            }
            else {
                return false
            }
        },
        async getCameras (serverId) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const response = await fetch(`${auth.baseUrl}/cameras/${serverId}`, request)
            if (response.ok) {
                const result =  await response.json()
                return result
            }
            else {
                return false
            }
        },
        async getRemoteCameras (serverId) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const response = await fetch(`${auth.baseUrl}/cameras/remote/${serverId}`, request)
            if (response.ok) {
                const result =  await response.json()
                return result
            }
            else {
                return false
            }
        },
        async addCamera (serverId, remoteId, name, description, lon, lat) {
            const auth = useAuthStore()
            const request = auth.buildRequest('POST', true, {
                'serverId': serverId,
                'remoteId': remoteId,
                'name': name,
                'description': description,
                'lon': lon,
                'lat': lat
            })
            const response = await fetch(`${auth.baseUrl}/cameras`, request)
            return response.status == 200;
        },
        async editCamera (cameraId, name, description, lon, lat) {
            const auth = useAuthStore()
            const request = auth.buildRequest('PUT', true, {                
                'name': name,
                'description': description,
                'lon': lon,
                'lat': lat
            })
            const response = await fetch(`${auth.baseUrl}/cameras/${cameraId}`, request)
            return response.status == 200 
        },
        async deleteCamera(cameraId) {
            const auth = useAuthStore()
            const request = auth.buildRequest('DELETE', true)
            const response = await fetch(`${auth.baseUrl}/cameras/${cameraId}`, request)
            return response.status == 200 
            
        },
        async ingestCamera(cameraId) {
            const auth = useAuthStore()
            const request = auth.buildRequest('POST', true)
            const response = await fetch(`${auth.baseUrl}/plate/ingest/${cameraId}`, request)
            return response.status == 200
        }
    }
})