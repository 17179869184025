<template>
    <div class="dashboard p-4 custom-scroll-container">
        <div class="lg:flex grid-cols-1 lg:space-y-0 space-y-3 gap-5 justify-between">
            <div>
                <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium">
                    Poblaciones
                </h1>
            </div>
            <div class="flex gap-2">
                <Modal :title="modalTitle" btnTextSubmit="Crear" btnText="Nuevo Población" ref="modalGroup"
                    @submit="getData()" @click="clearDataModal">
                    <template v-slot:body>
                        <div class="space-y-5 pb-5">
                            <div class="space-y-3">
                                <p>Nombre</p>
                                <input type="text" v-model="newGroupName" placeholder=""
                                    class="p-2 border dark:border-gray-600 dark:bg-gray-700 w-full rounded outline-none" />
                            </div>
                            <div>
                                <ImgUploadPreview @changeImage="recieveImage" :show-image="modalImage" />
                            </div>
                        </div>
                    </template>
                </Modal>
            </div>
        </div>
        <div class="bg-white dark:bg-gray-800 p-5 lg:mt-0 mt-4  border dark:border-gray-700 rounded-md w-full"
            style="height: 85%;">
            <div class="divide-y mt-5 dark:divide-gray-700 poem">
                <GroupItem v-for="(item, id) in groups" :key="id" :id="item.id" :name="item.name" />
            </div>
        </div>



    </div>
    <Modal v-show="false" ref="addusersGroup" title="Añadir usuarios" :subtitle="name" btnTextSubmit="Añadir"
        btnText="Añadir usuarios" @submit="addUsers"
        class="rounded-lg inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <template v-slot:body>
            <div class="space-y-5 pb-5">
                <div class="space-y-3">
                    <ul>
                        <li v-for="item in userList" :key="item.id">
                            <input name="selectusersToAdd" type="checkbox" 
                             :value="item.id" 
                             :checked="isUserGroup(item)" />
                            {{ item.name }}  
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </Modal>
    <Modal v-show="false" ref="groupUserModal" title="usuarios" :subtitle="name" btnTextSubmit="Añadir" btnText="OK"
        @submit="isOpen = false"
        class="rounded-lg inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
        <template v-slot:body>
            <div class="space-y-5 pb-5">
                <div class="space-y-3">
                    <ul>
                        <li v-for="item in userList" :key="item.id">
                            <input name="selectusersToAdd" type="checkbox" :value="item.id" />
                            {{ item.name }}
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </Modal>
    <Modal ref="ListUserModal" title="Usuarios de la población" v-show="false" btnTextSubmit="Ok"
        @submit="ListUserModal.isOpen = false">
        <template v-slot:body>
            <div class="divide-y h-96 mt-5 dark:divide-gray-700">
                <div class="p-3 w-full" v-for="item in groupUserList" :key="item.id">
                    <div class="flex gap-5 relative">
                        <div><img class="w-14 rounded-md" :src="getUserImage(item.id)" alt=""></div>
                        <div class="mt-1">
                            <h2 class="dark:text-gray-200">{{ item.name }}</h2>
                            <p class="text-sm dark:text-gray-500 text-gray-400"> {{ item.email }} </p>
                        </div>
                        <div class="right-float">
                            <button @click.prevent="deleteUserGroup(item.id)"
                                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                <Icon icon="mingcute:delete-fill" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>
<script setup>
import { useGroupStore } from '@/store/group'
import { useUsersStore } from "@/store/user"
import Modal from "@/components/modal.vue"
import GroupItem from "@/components/GroupItem.vue"
import { ref, onMounted, provide, watch, defineProps } from 'vue'
import { useImageStore } from '@/store/image'
import { Icon } from '@iconify/vue'
import ImgUploadPreview from '@/components/ImgUploadPreview.vue'

const modalImage = ref('')
const modalTitle = ref('')
const newGroupName = ref('')
const groups = ref([])
const modalGroup = ref(null)
const ListUserModal = ref()
const userList = ref([])
const addusersGroup = ref();
const currGroupId = ref('')
const groupUserList = ref([])
const groupId = ref('')

const isUserGroup = (item) => {    
    return item.groups.find((el) => { return el.id == currGroupId.value}) != undefined
}
const getData = async () => {
    const groupStore = useGroupStore()
    let result = false
    if (groupId.value != '') {
        result = await groupStore.editGroup(groupId.value, newGroupName.value)
    }
    else {
        result = await groupStore.addGroup(newGroupName.value)
    }
    if (result !== false) {
        
        modalGroup.value.isOpen = false
        if (modalImage.value != '') {

            const imageStore = useImageStore()
            imageStore.saveGroupImage(result, modalImage.value)
        }
        groups.value = await groupStore.getGroups()
    }
    else {
        alert('error')
    }
}

const getUsers = async (gId) => {
    const usersStore = useUsersStore()
    currGroupId.value = gId
    userList.value = await usersStore.getUsersInfo()

}
const getUserImage = async (uId) => {
    const imageStore = useImageStore()
    const response = await imageStore.getUserImage(uId)
    response ? 'data:image/jepg;base64, ' + response.image : ""
}
const addUsers = async () => {
    const userStore = useUsersStore()
    let inputs = document.querySelectorAll('input[name="selectusersToAdd"]');
    let selectedUsers = []
    inputs.forEach((el) => {
        if (el.checked) {
            selectedUsers.push({ id: el.value })
            userStore.addGroups(el.value, [currGroupId.value])
        }
    })
    addusersGroup.value.isOpen = false

}

const openAddUsers = async (gId) => {
    await getUsers(gId)
    addusersGroup.value.isOpen = true    
}

const openShowUsers = async (gId) => {
    const groupStore = useGroupStore()
    
    const response = await groupStore.getGroupUsers(gId)
    if (response) {
        ListUserModal.value.isOpen = true
        groupUserList.value = response.users
        currGroupId.value = gId
    }
}
const clearDataModal = () => {
    modalTitle.value = 'Añadir Población'
    groupId.value = ''
}
const deleteUserGroup = async (uId) => {
    const usersStore = useUsersStore()
    const response = await usersStore.deleteGroups(uId, [currGroupId.value])
    if (response) {
        const groupStore = useGroupStore()
        const gResponse = await groupStore.getGroupUsers(currGroupId.value)
        if (gResponse) {
            groupUserList.value = gResponse.users
        }
        groups.value = await groupStore.getGroups()
    }
}
const recieveImage = (data) => {
    modalImage.value = data
}

const getGroupImage = async (gId, id) => {
    const imageStore = useImageStore()
    imageStore.getGroupImage(gId).then((response) => {
        let groupImage = document.querySelector(`img[data-group-item="${id}"]`)
        if (response) {
            groupImage.src = 'data:image/jpeg;base64,' + response.image
        }
    })

}

onMounted(async (id) => {
    const groupStore = useGroupStore()
    groups.value = await groupStore.getGroups(id)
})

provide('userList', { userList, openAddUsers, openShowUsers, modalGroup, groupId, modalTitle, newGroupName, getGroupImage, modalImage })

</script>
<style>
.right-float {
    right: 0;
    position: absolute;
}

.poem {
    overflow-y: scroll;
    height: 100%;
    scrollbar-color: red orange;
    scrollbar-width: thin;
}

.custom-scroll-container {
    height: calc(100vh - 300px);
}
</style>