import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import VueApexCharts from "vue3-apexcharts";
import PerfectScrollbar from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { createPinia } from "pinia";
// import "flowbite";
import "./assets/tailwind.css";
import "./assets/animate.css";
import "./assets/sass/css/windzo.css";
import vClickOutside from "click-outside-vue3";
import VueGoogleMaps from '@fawmi/vue-google-maps'
import UUID from "vue-uuid"

const app = createApp(App);
app.use(UUID);
app.use(router);
app.use(createPinia());
app.use(VueApexCharts);
app.use(PerfectScrollbar);
app.use(vClickOutside);
app.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_MAPS_KEY,
        language: 'es',
        libraries: "places"
    },
}).mount("#app");