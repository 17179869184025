<template>
  <div class="wrapping-table mt-10 mb-5 ">
    <table
      class="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll overflow-y-scroll">
      <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
        <tr>
          <th scope="col" class="uppercase px-6 py-3 w-1/3">
            Categoria
          </th>
          <th scope="col" class="uppercase px-6 py-3 w-1/6">
            Nivel de peligrosidad
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
          v-for="items in categories" :key="items.transaction">
          <td class="px-6 py-4">
            <a href="#" @click.prevent="showUpdateCategory(items.id, items.name, items.dangerLevel)">
              {{ items.name }}
            </a>
          </td>
          <td class="px-6 py-4">
            {{ items.dangerLevel }}
          </td>
          <td>
            <button @click="deleteCategory(id, items.id)"
              class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <Icon icon="mingcute:delete-fill"></Icon>
            </button>
            <button @click="showAddPlate(id, items.id)"
              class="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <Icon icon="material-symbols:add-card-sharp"></Icon>
            </button>
            <button @click="showPlateList(items.id, items.name)"
              class="ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
              <Icon icon="ph:cards-fill"></Icon>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script setup>
import { watch, inject } from 'vue';
import { useCategoryStore } from '@/store/category';
import { Icon } from '@iconify/vue';


const { id, categories, getCategories, showAddPlate, showUpdateCategory, showPlateList } = inject('showCategories')

const deleteCategory = async (gId, cId) => {
  const categoryStore = useCategoryStore()
  const response = await categoryStore.deleteCategory(gId, cId);
  if (response) {
    await getCategories(gId)
  }
}
watch(id, (newId, oldId) => {
  getCategories()
})
</script>
<style scoped>
tbody tr:hover {
  background-color: aqua;
}
</style>