<template>
    <div class="dashboard p-4">
        <div class="mt-2 w-full">
            <div class="lg:flex grid-cols-1 lg:space-y-0 space-y-3 gap-5 justify-between">
                <div>
                    <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium">
                        Panel
                    </h1>
                </div>
                <div class="flex gap-2"></div>
            </div>
        </div>
        <div class="mt-2 lg:flex block lg:gap-2 relative">
            <div class="bg-white dark:bg-gray-800 p-0 w-full rounded-md box-border border dark:border-gray-700">
                <div class="p-5 flex justify-between">
                    <div>
                        <h2 class="font-medium text-sm text-gray-800 dark:text-gray-200">
                            Capturas en el mes
                        </h2>
                        <h1 class="font-semibold text-4xl text-gray-800 dark:text-gray-200">
                            <Icon icon="tabler:capture" class="inline-flex" />
                            {{ monthTotal }}
                        </h1>
                    </div>
                    <div>
                        <h2 class="font-medium text-sm text-gray-800 dark:text-gray-200">
                            Capturas en los &uacute;ltimos 30 dias
                        </h2>
                        <h1 class="font-semibold text-4xl text-gray-800 dark:text-gray-200">
                            <Icon icon="tabler:capture" class="inline-flex" />
                            {{ last30days }} 
                        </h1>
                    </div>
                    <div>
                        <h2 class="font-medium text-sm text-gray-800 dark:text-gray-200">
                            Capturas en el dia
                        </h2>
                        <h1 class="font-semibold text-4xl text-gray-800 dark:text-gray-200">
                            <Icon icon="tabler:capture" class="inline-flex" />
                            {{ dayTotal }}
                        </h1>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2 lg:flex block lg:gap-2 relative">
            <div class="bg-white dark:bg-gray-800 p-0 w-full rounded-md box-border border dark:border-gray-700">
                <div class="p-5 flex justify-between">
                    <div>
                        <h2 class="font-medium text-sm text-gray-800 dark:text-gray-200">
                            Capturas en el dia
                        </h2>
                        <h1 class="font-semibold text-4xl text-gray-800 dark:text-gray-200">
                            {{ total }}
                        </h1>
                    </div>
                    <div class="w-1/5">
                        <VueTailwindDatepicker as-single i18n="es" :formatter="formatter" v-model="date" />
                    </div>
                </div>
                <VueApexCharts type="bar" width="100%" height="350" :options="options" :series="series" ref="graf"
                    :sparkline="{
                        enabled: true,
                    }" />
            </div>
        </div>

        <div class="mt-2 lg:flex block lg:gap-2 relative">
            <div class="bg-white dark:bg-gray-800 p-0 w-1/2 rounded-md box-border border dark:border-gray-700">
                <div class="p-5 flex justify-between">
                    <div>
                        <h2 class="font-medium text-sm text-gray-800 dark:text-gray-200">
                            Capturas en la semana
                        </h2>
                        <h1 class="font-semibold text-4xl text-gray-800 dark:text-gray-200">
                            {{ weekTotal }}
                        </h1>
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <div>
                        <VueApexCharts type="pie" width="100%" height="350" :options="optionsPie" :series="seriesPie" />
                    </div>
                    <div class="mb-10">
                        <VueTailwindDatepicker class="w-full" as-single no-input i18n="es" :formatter="formatter"
                            v-model="week" />
                    </div>
                </div>
            </div>
            <div class="bg-white dark:bg-gray-800 p-0 w-1/2 rounded-md box-border border dark:border-gray-700">
                <div class="p-5 flex justify-between">
                    <div>
                        <h2 class="font-medium text-sm text-gray-800 dark:text-gray-200">
                            Capturas por categoria en 24h
                        </h2>
                        <h1 class="font-semibold text-4xl text-gray-800 dark:text-gray-200">
                            {{ categoryTotal }}
                        </h1>
                    </div>
                </div>
                <div class="grid grid-cols-2 gap-2">
                    <div>
                        <VueApexCharts type="bar" width="100%" height="350" :options="optionsCategory"
                            :series="seriesCategory" />
                    </div>
                    <div class="mb-10">
                        <VueTailwindDatepicker class="w-full" as-single no-input i18n="es" :formatter="formatter"
                            v-model="categoryDate" />
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
  
<script setup>
import { onMounted, ref, watch } from "vue";
import { useStatsStore } from "@/store/stats"
import VueApexCharts from "vue3-apexcharts";
import VueTailwindDatepicker from "vue-tailwind-datepicker"
import { Icon } from "@iconify/vue";
const last30days = ref(0)
const formatter = ref({
    date: 'DD MMMM YYYY',
    month: 'MMM',
    day: 'D'
})
const dayTotal = ref(0)
const date = ref('')
const week = ref('')
const categoryDate = ref('')
const monthTotal = ref(0)
const weekTotal = ref(0)
const categoryTotal = ref(0)
const options = ref({
    chart: {
        id: 'vuechat-day-count'
    },
    stroke: {
        curve: "straight",
        width: 2
    },
    toolbar: {
        show: false
    },
    dataLabels: {
        enabled: false
    },
})
const series = ref([{
    name: "capturas",
    data: []
}])

const optionsPie = ref({
    chart: {
        type: 'donut',
        id: 'vuechart-week-count'
    },
    dataLabels: {
        enabled: true
    },
    legend: {
        show: false
    },
    labels: ['Lunes', 'Martes', 'Miercooles', 'Jueves', 'Viernes', 'Sabado', 'Domingo'],
    plotOptions: {
        pie: {
            donut: {
                labels: {
                    show: true,
                }
            }
        }
    }
})
const seriesPie = ref([20, 15, 63, 83])

const optionsCategory = ref({
    chart: {
        type: 'bar',
        height: 350
    },
    plotOptions: {
        bar: {
            borderRadius: 4,
            horizontal: true,
        }
    },
    dataLabels: {
        enabled: false
    },
    xaxis: {
        categories: []
    }
})
const seriesCategory = ref([
    {
        data: []
    }
])

const total = ref(0)
const getStats = async (day, is24h) => {
    const statsStore = useStatsStore()
    const stats = await statsStore.getDailyCaptures(day, is24h)
    if (stats) {
        total.value = 0
        let cTotal = 0
        series.value[0].data = []
        stats.forEach(element => {
            const t = element.timestamp.toString()
            series.value[0].data.push({ x: t, y: element.count })
            cTotal += element.count
        })
        total.value = cTotal
    }
}
const getWeekStats = async (day) => {
    const statsStore = useStatsStore()
    const stats = await statsStore.getWeekCaptures(day)
    if (stats) {
        weekTotal.value = 0
        seriesPie.value = []
        stats.forEach((el) => {
            seriesPie.value.push(el.count)
            weekTotal.value += el.count
        })
    }
}
const getMountCount = async (year, month) => {
    const statsStore = useStatsStore()
    const stats = await statsStore.getMountCount(year, month + 1)
    if (stats) {
        monthTotal.value = stats
    }
}
const getDayCount = async (year, month, day) => {
    const statsStore = useStatsStore()
    const stats = await statsStore.getDayCount(year, month + 1, day)
    
    if (stats !== false) {
        dayTotal.value = stats
    }
}
const getCategoryStats = async (day) => {
    const statsStore = useStatsStore()
    const dayStart = day + 'T00:00:00.000'
    const dayEnd = day + 'T23:59:59.999'

    const stats = await statsStore.getCategoryStats(dayStart, dayEnd)
    if (stats) {
        let total = 0

        seriesCategory.value = []
        stats.forEach((el) => {
            seriesCategory.value.push({
                name: el.name,
                data: [el.count]
            })
            total += el.count
        })

        categoryTotal.value = total
    }
}
const pickerToDate = (nDate) => {
    const d = nDate.split(' ')
    switch (d[1]) {
        case "enero": d[1] = '01'; break;
        case "febrero": d[1] = '02'; break;
        case "marzo": d[1] = '03'; break;
        case "abril": d[1] = '04'; break;
        case "mayo": d[1] = '05'; break;
        case "junio": d[1] = '06'; break;
        case "julio": d[1] = '07'; break;
        case "agosto": d[1] = '08'; break;
        case "septiembre": d[1] = '09'; break;
        case "octubre": d[1] = '10'; break;
        case "noviembre": d[1] = '11'; break;
        case "diciembre": d[1] = '12'; break;
    }
    return `${d[2]}-${d[1]}-${d[0]}`
}
onMounted(async () => {
    const today = new Date()
    const month = ("0" + (today.getMonth() + 1)).slice(-2)
    const strDate = `${today.getFullYear().toString()}-${month}-${today.getDate()}`
    getStats(new Date(Date.now()).toISOString() , true)
    getWeekStats(strDate)
    getMountCount(today.getFullYear(), today.getMonth())
    getDayCount(today.getFullYear(), today.getMonth(), today.getDate())
    getCategoryStats(strDate)
    const statsStore = useStatsStore()
    const response = await statsStore.getDaysBefore(strDate, 30)
    if (response !== false) {
        last30days.value = response        
    }
}),
    watch(date, (nDate, oDate) => {
        getStats(pickerToDate(nDate))
    }),
    watch(week, (nDate, oDate) => {
        getWeekStats(pickerToDate(nDate))
    }),
    watch(categoryDate, (nDate, oDate) => {
        getCategoryStats(pickerToDate(nDate))
    })

</script>
  