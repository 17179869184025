<template>
    <div class="dashboard p-4">
        <div class="lg:flex grid-cols-1 lg:space-y-0 space-y-3 gap-5 justify-between">
            <div>
                <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium">
                    Servidores
                </h1>
            </div>
            <div class="flex gap-2">
                <Modal ref="modalAdd" btnText="A&ntilde;adir servidor remoto" btnTextSubmit="Crear"
                    @click="showAddServer"
                    :title="modalTitle" @submit="updateOrAdd">
                    <template v-slot:body>
                        <input type="text" placeholder="nombre"
                            class="p-2 border dark:border-gray-600 dark:bg-gray-700 w-full rounded outline-none"
                            v-model="nServerName" />
                            
                            <!-- begin server types-->
                            <select v-model="serverType"
                                @change="updateParamTemplate"
                                class="dark:bg-gray-800 dark:hover:bg-gray-700 
                                       border dark:border-gray-700 max-w-lg px-4 
                                       w-full
                                       py-3 rounded-md text-gray-500 dark:text-gray-400">
                                <option value="">Tipo de servidor</option>
                                <option v-for="(item, type) in serverTypes" :key="type" :value="item.type">
                                    {{ item.name }}
                                </option>
                            </select>
                            <!-- end server types-->

                        <div class="mt-5">
                            <table v-show="serverType != ''"
                                class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
                                <thead
                                    class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th>Parametro</th>
                                        <th>Valor</th>
                                        <th style="background: none;"><button @click="addNewParam"
                                                class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                                <Icon icon="zondicons:add-solid" />

                                            </button></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, id) in serverParms" :key="id">
                                        <td>                                            
                                            <input type="text" v-model="item.name"
                                                :disabled="item.disabledName === true"
                                                class="p-2 border dark:border-gray-600 dark:bg-gray-700 w-full rounded outline-none" />
                                        </td>
                                        <td><input type="text" v-model="item.value" :placeholder="item.label"
                                                class="p-2 border dark:border-gray-600 dark:bg-gray-700 w-full rounded outline-none" />
                                        </td>
                                        <td class="pl-2">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </Modal>
            </div>
        </div>
        <div class="bg-white dark:bg-gray-800 p-5 lg:mt-0 mt-4  border dark:border-gray-700 rounded-md w-full">
            <div class="ps divide-y h-96 mt-5 dark:divide-gray-700 ps--active-y">
                <div class="pb-3">
                    <label class="dark:text-gray-200 pr-3">Poblaci&oacute;n</label>
                    <select v-model="groupId" @click="showGroupServers"
                        class="w-1/2 dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400">
                        <option value="">Sin poblaci&oacute;n</option>
                        <option v-for="(item, id) in groupList" :key="id" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
                <div>
                    <table
                        class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
                        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" class="uppercase px-6 py-3 w-1/4">
                                    Id
                                </th>
                                <th scope="col" class="uppercase px-6 py-3">
                                    Nombre
                                </th>
                                <th>
                                    Tipo
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50"
                                v-for="item in servers" :key="item.id">
                                <td class="px-6 py-4">                                    
                                    <a href='#' @click.prevent="showUpdateServer(item.id)"> {{ item.id }}</a>
                                </td>
                                <td class="px-6 py-4">                                    
                                    {{ item.name }}
                                </td>
                                <td class="px-6 py-4">
                                    {{ item.apiType }}
                                </td>
                                <td>
                                    <button @click="deleteServer(item.id)"
                                        class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                        <Icon icon="mingcute:delete-fill"></Icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref, reactive  } from 'vue';
import { useGroupStore } from '@/store/group';
import { useServerStore } from '@/store/server'
import Modal from '@/components/modal.vue';
import { Icon } from '@iconify/vue';
const modalTitle = ref('')
const nServerName = ref('')
const serverParms = ref([
    {
        name: '',
        value: '',
        disabledName: false
    }
])

const serverType = ref('')
const serverTypes = ref([
    {
        name: "Servidor Nx V5.1",
        type: "Nx",
        parameters: [
            {
                label: 'Identificardor',
                field: 'UrlUID',
                disabledName: true
            },
            {
                label: 'Usuario',
                field: 'User',
                disabledName: true
            },
            {
                label: 'Clave',
                field: 'Password',
                disabledName: true
            }
        ]
    }
])

const serverId = ref('')
const groupId = ref('')
const servers = ref([])
const modalAdd = ref(null)
const groupList = ref([])
const updateParamTemplate = () => {
    serverParms.value = []
    serverTypes.value
        .find((el) => el.type == serverType.value)?.parameters
            .forEach((el) => serverParms.value.push(
                { 
                    name: el.field, value: '', 
                    label: el.label,
                    disabledName: el.disabledName
            
                }));
    
}

const deleteServer = async (sId) => {
    const serverStore = useServerStore()
    const respose = await serverStore.deleteServer(sId)
    if (respose != null) {
        showGroupServers()
    }
}
const updateOrAdd = () => {
    if (serverId.value == '') addServer()
    else updateServer()
}
const addServer = async () => {
    const serverStore = useServerStore()
    const response = await serverStore.addServer(nServerName.value, serverParms.value, groupId.value)

    if (response != false) {
        servers.value.push({
            id: response,
            name: nServerName.value
        })
        modalAdd.value.isOpen = false
    }
}
const updateServer = async () => {
    const serverStore = useServerStore()
    const response = await serverStore.updateServer(serverId.value, nServerName.value, serverParms.value, groupId.value)
    if (response) {
        showGroupServers()
        modalAdd.value.isOpen = false
    }
}

const showGroupServers = async () => {
    servers.value = []
    if (groupId.value != '') {
        const serverStore = useServerStore()
        const response = await serverStore.getGroupServers(groupId.value)
        if (response != false) {
            servers.value = response
        }
    }
}
const addNewParam = () => {
    serverParms.value.push({
        name: '',
        value: ''
    })
}
const showAddServer = () => {
    serverId.value = ''
    nServerName.value = ''
    serverType.value = ''
    modalTitle.value = "Añadir servidor remoto"
}
const showUpdateServer = async (sId) => {
    const serverStore= useServerStore()
    const server = servers.value.find((el) => el.id == sId)
    if (server) {
        modalTitle.value = "Editar servidor ["+ server.name +"]"
        serverId.value = sId
        nServerName.value = server.name
        serverType.value = server.apiType
        updateParamTemplate()        
        const serverInfo = await serverStore.getServer(sId);
        if (serverInfo) {
            serverParms.value.forEach((p) => {                
                let sParam = serverInfo.serverConfig.find((x) => x.name == p.name)                
                if (sParam) {
                    p.value = sParam.value
                }
            })
        }
        modalAdd.value.isOpen = true        
    }
    
}
onMounted(async () => {
    const groupStore = useGroupStore()
    const response = await groupStore.getGroups()
    if (response != false) {
        groupList.value = response
    }
})
</script>
<style scoped>
.lb-field {
    margin-right: 1rem;
}
</style>