<template>
    <div class="dashboard p-4 ml-2">
        <div class="lg:flex grid-cols-1 lg:space-y-0 space-y-3 gap-5 justify-between mb-5">
            <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium">
                Alertas
            </h1>
        </div>
        <div class="mt-5 bg-white dark:bg-gray-800 p-5 lg:mt-0 mt-4 border dark:border-gray-700 rounded-md w-full">
            <label class="dark:text-gray-200 pr-3">Población</label>
            <select v-model="groupId"
                class="w-1/4 dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400 inline-flex">
                <option v-for="(item) in groups" :key="item.id" :value="item.id">{{ item.name }}</option>
            </select>
            <Modal btnText="Añadir nueva alerta" ref="modalAddAlert" :title="modalTitle" btnTextSubmit="Crear"
                v-show="groupId != ''" class="ml-3 inline-flex" @submit="addAlert" width="w-1/3"
                @click="newAlert.name = ''; newAlert.id = ''; newAlert.destinations = []; newAlert.patterns = []">
                <template v-slot:body>
                    <div>
                        <label class="block dark:text-white-400 ">Descripci&oacute;n</label>
                        <input
                            class="w-full uppercase uppercasep-3 bg-white dark:bg-gray-900 dark:text-gray-400 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
                            type="text" v-model="newAlert.description" />

                        <div class="mt-4 grid grid-cols-2">
                            <div class="pr-2">
                                <p
                                    class="mb-2 bg-gray-400 dark:bg-gray-500 rounded rounded-md text-center block dark:text-white-400 w-full py-2 mt-3">
                                    Matr&iacute;culas</p>
                                <input
                                    class="inline-flex uppercase p-3 bg-white dark:bg-gray-900 dark:text-gray-400 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
                                    type="text" ref="inputPlate" />
                                <button
                                    class="ml-3 inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                    @click.prevent="addModalPlate">
                                    Añadir
                                </button>
                                <ul>
                                    <li v-for="(item, idx) in newAlert.patterns">
                                        {{ item }}
                                        <button @click="newAlert.patterns.splice(idx, 1)"
                                            class="ml-3 inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                            <Icon icon="mingcute:delete-fill"></Icon>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                            <div class="pl-2">
                                <p
                                    class="mb-2 bg-gray-400 dark:bg-gray-500 rounded rounded-md text-center block dark:text-white-400 w-full py-2 mt-3">
                                    Tel&eacute;fonos</p>

                                <input
                                    class="inline-flex uppercase p-3 bg-white dark:bg-gray-900 dark:text-gray-400 rounded-md outline-none focus:bg-gray-100 dark:focus:bg-gray-700"
                                    maxlength="9" type="text"
                                    @keypress="(e) => { if (e.key < '0' || e.key > '9') e.preventDefault() }"
                                    ref="inputDestiny" />
                                <button
                                    class="ml-3 inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                                    @click.prevent="addPhoneAlert">
                                    Añadir
                                </button>
                                <ul>
                                    <li v-for="(item, idx) in newAlert.destinations">
                                        {{ item }}
                                        <button @click="newAlert.destinations.splice(idx, 1)"
                                            class="ml-3 inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                            <Icon icon="mingcute:delete-fill"></Icon>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </template>
            </Modal>
            <perfect-scrollbar class="h-screen mt-5">
                <div class="wrapping-table mb-5">
                    <table
                        class="w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll">
                        <thead
                            class="h-10 px-2 rounded rounded-md text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 sticky top-0">
                            <tr>
                                <td>Alerta</td>
                                <td>Matricula</td>
                                <td>Destinatarios</td>
                                <td></td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, idx) in alerts" :key="item.id"
                                class="py-2 bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50">
                                <td>{{ item.description }}</td>
                                <td>
                                    <span v-for="pattern in item.patterns" :key="pattern.id">
                                        {{ pattern.pattern }}
                                    </span>
                                </td>
                                <td>
                                    <span v-for="destiny in item.destinations" :key="destiny.id">
                                        {{ destiny.destiny }}
                                    </span>
                                </td>
                                <td>
                                    <button
                                        @click="deleteAlert(item.id)"
                                        class="ml-3 inline-flex text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                                        <Icon icon="mingcute:delete-fill"></Icon>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
</template>
<script setup>
import { useGroupStore } from '@/store/group';
import { onMounted, ref, watch } from 'vue';
import Modal from '@/components/modal.vue';
import { Icon } from '@iconify/vue';
import { useAlertStore } from '@/store/alert.js';
const groupId = ref('')
const groups = ref([])
const alerts = ref([])
const modalTitle = ref('')
const modalAddAlert = ref()
const inputPlate = ref()
const inputDestiny = ref()
const newAlert = ref({
    id: '',
    description: '',
    patterns: [],
    destinations: []
})

const addAlert = () => {
    const alertStore = useAlertStore()
    alertStore.addAlert(groupId.value, newAlert.value.description.toUpperCase(), newAlert.value.destinations, newAlert.value.patterns)
        .then((response) => {
            modalAddAlert.value.isOpen = false
            getAlters()
        })
}

const addModalPlate = (event) => {
    if (inputPlate.value.value != '') {
        newAlert.value.patterns.push(inputPlate.value.value.toUpperCase())
        inputPlate.value.value = ''
    }

}
const addPhoneAlert = () => {
    if (inputDestiny.value.value != '') {
        newAlert.value.destinations.push(inputDestiny.value.value.toUpperCase())
        inputDestiny.value.value = ''
    }
}
const getAlters = () => {
    const alertStore = useAlertStore()
    alertStore.getGroupAlerts(groupId.value)
        .then((response) => {
            if (response !== false) {
                alerts.value = response
            }
        })
}

const deleteAlert = (id) => {
    const alertStore = useAlertStore()
    alertStore.deleteAlert(id)
      .then((response) => {
        if (response === true) {
            getAlters()
        }
      })

}
onMounted(() => {
    const groupStore = useGroupStore()
    groupStore.getGroups().then(response => {
        groups.value = response
    })
})

watch(groupId, (newGroupId, oldGroupId) => {
    getAlters()
    const groupName = document.querySelector('option[value="' + groupId.value + '"]')
    if (groupName) {
        modalTitle.value = `Añadir alerta en ${groupName.textContent}`
    }
})
</script>