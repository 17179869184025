<template>
    <label v-show="label"
       class="inline-flex dark:bg-gray-800 dark:hover:bg-gray-700                 
                dark:border-gray-700 
                max-w-lg px-2 py-1 rounded-md text-gray-500 dark:text-gray-400">
                {{ label }}
            </label>
    <div v-show="!showPicker" 
              class="inline-flex dark:bg-gray-800 dark:hover:bg-gray-700 
                border 
                dark:border-gray-700 
                max-w-lg px-2 rounded-md text-gray-500 dark:text-gray-400">
        <div class="inline-flex">
            <input style="max-width: 6rem;" 
            class="inline-flex no-border dark:bg-gray-800 dark:hover:bg-gray-700 text-gray-500 dark:text-gray-400" type="text" v-model="time" 
            @click="showPicker = true"/>
            <span  @click="clearTime"
               class="inline-block pt-3                      
                       text-gray-500 dark:text-gray-400">
                <Icon icon="ic:round-clear" />
            </span>
        </div>
    </div>
    <div v-show="showPicker" class="inline-flex dark:bg-gray-800 dark:hover:bg-gray-700 
                border 
                dark:border-gray-700 
                max-w-lg  rounded-md text-gray-500 dark:text-gray-400">
        <select v-model="hour" style="width: 4rem;" 
                class="l dark:bg-gray-800 
                       no-border 
                       dark:border-gray-700 
                       max-w-lg px-2 py-2 text-gray-500 dark:text-gray-400">
            <option v-for="(n, i) in 24" :value="i">{{ i.toString().padStart(2, '0') }}</option>
        </select>
        <select style="width: 4rem;" v-model="minute" class="r dark:bg-gray-800 dark:hover:bg-
                no-border              
        gray-700 border dark:border-gray-700 
                      max-w-lg px-2 py-2 text-gray-500 dark:text-gray-400">
            <option v-for="(n, i) in 59" :value="i">{{ i.toString().padStart(2, '0') }}</option>
        </select>
        <span class="ml-1 mr-2 inline-flex 
                       dark:bg-gray-800 
                       max-w-lg px-4 py-2 rounded-md text-gray-500 dark:text-gray-400" @click.prevent="setValue">
            <Icon icon="ic:round-check" />
        </span>
    </div>
</template>
<script setup>
import { ref } from "vue";
import { Icon } from "@iconify/vue";
const showPicker = ref(false)
const hour = ref(0)
const minute = ref(0)
const emit = defineEmits({
    timeChange: (time) => {
        return time
    }
})
const clearTime = () => {
    time.value = ''
    emit('timeChange', time.value)
}
const props = defineProps({
    time: String,
    label: String    
})

const time = ref('')
const setValue = () => {
    time.value = hour.value.toString().padStart(2, '0') + ":" + minute.value.toString().padStart(2, '0')
    showPicker.value = false
    emit('timeChange', time.value)
}
</script>
<style>
.l {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-right-style: none;

}

.l::after {
    content: ':';
}

.r {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-left-style: none;
}

.no-border {
    border: none;
}
</style>