import { defineStore } from "pinia";
import { useAuthStore } from "./auth";
import { uuid } from "vue-uuid"

export const useCategoryStore = defineStore('category',
    {
        actions: {
            async addCategory(groupId, name, dangerLevel) {
                const auth = useAuthStore()
                const id = uuid.v1()
                const request = auth.buildRequest('POST', true,
                    {
                        'id': id,
                        'name': name,
                        'dargerLevel': parseInt(dangerLevel)
                    })
                const response = await fetch(`${auth.baseUrl}/categories/${groupId}`, request)
                return response.status == 200 ? id : false;
            },
            async getAllCategories() {
                const auth = useAuthStore()
                const request = auth.buildRequest('GET', true)
                const response = await fetch(`${auth.baseUrl}/categories`, request)
                return response.status == 200 ? await response.json() : false
            },
            async getCategories(groupId) {
                const auth = useAuthStore()
                const request = auth.buildRequest('GET', true)
                const response = await fetch(`${auth.baseUrl}/categories/${groupId}`, request)
                return response.status == 200 ? await response.json() : false
            },
            async deleteCategory(groupId, categoryId) {
                const auth = useAuthStore()
                const request = auth.buildRequest('DELETE', true)
                const response = await fetch(`${auth.baseUrl}/categories/${groupId}/${categoryId}`, request)
                return response.status == 200;
            },
            async updateCategory(groupId, categoryId, name, level) {
                const auth = useAuthStore()                
                const request = auth.buildRequest('PUT', true,
                    {
                        'id': categoryId,
                        'name': name,
                        'dargerLevel': parseInt(level)
                    })
                const response = await fetch(`${auth.baseUrl}/categories/${groupId}`, request)
                return response.status == 200;
            },
            async getCategoryPlates(id) {
                const auth = useAuthStore()
                const request = auth.buildRequest('GET', true)
                const response = await fetch(`${auth.baseUrl}/categories/${id}/plates`, request)
                return response.status == 200 ? await response.json() : false
            },
            async removeCategoryPlate(id, plateId) {
                const auth = useAuthStore()
                const request = auth.buildRequest('DELETE', true, [
                    plateId
                ])
                const response = await fetch(`${auth.baseUrl}/categories/${id}/plates`, request)
                return response.status == 200
            },
        }
    }
)
