<template>
    <div class="dashboard p-4">
        <div class="lg:flex grid-cols-1 lg:space-y-0 space-y-3 gap-5 justify-between">
            <div>
                <h1 class="text-2xl text-gray-900 dark:text-gray-200 font-medium">
                    Categorias
                </h1>
                <br />
            </div>
        </div>
        <div class="bg-white dark:bg-gray-800 p-5 lg:mt-0 mt-4 border dark:border-gray-700 rounded-md w-full">
            <div class="ps h-96 mt-5 dark:divide-gray-700 ps--active-y">

                <label class="dark:text-gray-200 pr-3">Poblaci&oacute;n</label>
                <select v-model="id" @select="getCategories"
                    class="dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400">
                    <option v-for="(item, id) in groups" :key="id" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>
                <div class="button-container">
                    <Modal :title="modalTitle" btnTextSubmit="Guardar" btnText="Nueva categoria" ref="modalAdd"
                        @click="showAddCategory" @submit="updateOrAdd">
                        <template v-slot:body>
                            <div class="space-y-5 pb-5">
                                <div class="space-y-3">
                                    <p>Nombre</p>
                                    <input type="text" v-model="nCategoryName" placeholder=""
                                        class="ml-3 p-2 border dark:border-gray-600 dark:bg-gray-700 w-full rounded outline-none" />
                                </div>
                            </div>
                            <div class="space-y-5 pb-5">
                                <div class="space-y-3">
                                    <p>Nivel de peligrosidad</p>
                                    <select
                                        class="dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400"
                                        v-model="nCategoryLevel">
                                        <option>0</option>
                                        <option>1</option>
                                        <option>2</option>
                                        <option>3</option>
                                        <option>4</option>
                                        <option>5</option>

                                    </select>
                                </div>
                            </div>
                        </template>
                    </Modal>
                </div>
                <CategoryList />
            </div>
        </div>
    </div>
    <Modal v-show="false" ref="modalPlateAdd" title="Crear matricula" @submit="addPlateCategory" btnTextClose="Cerrar"
        btnTextSubmit="crear">
        <template v-slot:body>
            <input
                class="dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400"
                v-model="plateValue" placeholder="matricula" />
        </template>
    </Modal>
    <Modal v-show="false" ref="plateListModal" :title="plateListTitle" btnTextSubmit="Ok" btnTextClose=""
        @submit="plateListModal.isOpen = false">
        <template v-slot:body>
            <div class="">
                <label
                    class="dark:bg-gray-800 dark:hover:bg-gray-700 max-w-lg px-4 py-3  text-gray-500 dark:text-gray-400">Filtro:
                </label>
                <input type="text" v-model="plateFilter"
                    class="dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400" />
            </div>
            <div class="wrapping-table mt-10 mb-5 table-scroll">
                <table
                    class="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400 lg:overflow-auto overflow-x-scroll overflow-y-scroll">
                    <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th>Matricula</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700 odd:bg-white even:bg-gray-50 py-2"
                            v-for="(item, idx) in plates" :key="idx"
                            v-show="plateFilter == '' || item.plate.includes(plateFilter)">
                            <td class="px-6 py-4">{{ item.plate }}</td>
                            <td>
                                <button
                                    @click.prevent="removeCategoryPlate(item.id, idx)"
                                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <Icon icon="mingcute:delete-fill"></Icon>
                                </button>
                                <button
                                    @click.prevent="showPlateTrack(item.groupId, item.id, item.plate)"
                                    class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                                    <Icon icon="ooui:map-pin"></Icon>
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </Modal>
    <Modal v-show="false" btnIcon="ri:map-pin-fill" btn-text="" ref="mapModalTrakcer"
        @submit="mapModalTrakcer.isOpen = false" :title="mapTrackerTitle" btn-text-submit="Ok">
        <template v-slot:body>
            <Mapmaker ref="gMapObj" :markers="plateTrackInfo" :lastLocation="lastLocation" />
        </template>
    </Modal>
</template>
<script setup>
import CategoryList from '@/components/CategoryList.vue'
import { useGroupStore } from '@/store/group';
import { useCategoryStore } from '@/store/category';
import { ref, onMounted, watch, provide } from 'vue'
import Modal from '@/components/modal.vue';
import { usePlateStore } from '@/store/plate';
import { Icon } from '@iconify/vue';
import Mapmaker from '@/components/mapmaker.vue';
const plateFilter = ref('')
const mapTrackerTitle = ref('')
const plates = ref([])
const plateListModal = ref()
const plateListTitle = ref('')
const modalTitle = ref('')
const modalPlateAdd = ref()
const plateValue = ref('')
const groups = ref([])
const id = ref('')
const plateCategoryId = ref('')
const nCategoryName = ref('')
const nCategoryLevel = ref(0)
const modalAdd = ref(null)
const categories = ref([])
const categoryId = ref('')
const plateTrackInfo = ref([])
const lastLocation = ref({})
const mapModalTrakcer = ref()
const showIdCategory = ref('')
const updateOrAdd = () => {
    if (categoryId.value == '')
        addCategory()
    else
        updateCategory()
}
const addCategory = async () => {
    const categoryStore = useCategoryStore()
    const response = await categoryStore.addCategory(id.value, nCategoryName.value, nCategoryLevel.value)
    if (response !== false) {
        getCategories(id.value)
    }
    modalAdd.value.isOpen = false;
}
const updateCategory = async () => {
    const categoryStore = useCategoryStore()
    const response = await categoryStore.updateCategory(id.value, categoryId.value, nCategoryName.value, nCategoryLevel.value)
    if (response !== false) {
        getCategories(id.value)
    }
    modalAdd.value.isOpen = false;
}
onMounted(async () => {
    const groupStore = useGroupStore()
    groups.value = await groupStore.getGroups()
})

const getCategories = async () => {
    const categoryStore = useCategoryStore()
    const response = await categoryStore.getCategories(id.value)
    if (response) {
        if (response != false) {
            categories.value = response
        }
        else {
            categories.value = []
        }
    }
}

const addPlateCategory = () => {
    const plateStore = usePlateStore()
    plateStore.addPlate(id.value, plateValue.value, plateCategoryId.value)
    modalPlateAdd.value.isOpen = false
}
const showAddPlate = (gId, catId) => {
    id.value = gId
    plateCategoryId.value = catId
    modalPlateAdd.value.isOpen = true
    plateValue.value = ''
}
const showUpdateCategory = (catId, name, level) => {
    nCategoryLevel.value = level
    nCategoryName.value = name
    categoryId.value = catId
    modalTitle.value = 'Editar categoria [' + name + ']'
    modalAdd.value.isOpen = true
}
const showAddCategory = () => {
    modalTitle.value = 'Crear categoria'
    nCategoryLevel.value = 0
    nCategoryName.value = ''
    categoryId.value = ''
}
const showPlateList = async (id, name) => {
    showIdCategory.value = id
    const categoryStore = useCategoryStore()
    const response = await categoryStore.getCategoryPlates(id)
    if (response !== false) {
        plates.value = response
        plateListModal.value.isOpen = true
        plateListTitle.value = 'Matriculas de categoria: ' + name
    }

}
const showPlateTrack = async (gId, pId, plateValue) => {
  const plateStore = usePlateStore()
  const trackResponse = await plateStore.getPlateTrack(gId, pId)
  mapTrackerTitle.value = `Rastreo de matricula: ${plateValue}`
  if (trackResponse) {
    plateTrackInfo.value = trackResponse
    lastLocation.value = trackResponse[trackResponse.length - 1].location
    mapModalTrakcer.value.isOpen = true
  }
}

const removeCategoryPlate = async (pId, idx) => {
    const categoryStore = useCategoryStore()
    const response = await categoryStore.removeCategoryPlate(showIdCategory.value, pId)
    if (response !== false) {
        plates.value.splice(idx, 1)        
    }

}
provide('showCategories', { id, categories, getCategories, showAddPlate, showUpdateCategory, showPlateList })
</script>
<style scoped>
select {
    padding: 5px;
    margin-left: 10px;
    margin-bottom: 20px;
    width: 20rem;
}

.button-container {
    display: inline-flex;
    padding-left: 1rem;
}

.table-scroll {
    height: 20rem;
    overflow-y: scroll;
    scroll-behavior: smooth;
    scrollbar-color: blue;
}</style>