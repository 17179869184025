import { defineStore } from "pinia"
import { useAuthStore } from "./auth"
import { uuid } from "vue-uuid"
export const useAlertStore = defineStore('alert', {
    actions: {
        async getGroupAlerts(groupId) {
            const authStore = useAuthStore()
            const request = authStore.buildRequest('GET', true)
            const response = await fetch (`${authStore.baseUrl}/alerts/${groupId}`, request)
            
            return response.status == 200 ? await response.json() : false
        },
        async addAlert(groupId, name, destinations, patterns) {
            const authStore = useAuthStore()
            const id = uuid.v1()
            const request = authStore.buildRequest('POST', true, {
                'id': id,
                'description': name,
                'destinations': destinations,
                'patterns': patterns
            })
            const response = await fetch (`${authStore.baseUrl}/alerts/${groupId}`, request)
            response.status == 200 ? id : false
        },
        async deleteAlert(alertId) {
            const authStore = useAuthStore()
            const request = authStore.buildRequest('DELETE', true)
            const response = await fetch (`${authStore.baseUrl}/alerts/${alertId}`, request)
            
            return response.status == 200
        }
    }
})