import { defineStore } from 'pinia'
import { useAuthStore } from "./auth";
export const useUsersStore = defineStore('users', {
    state: () => {
        return {
            userName: '',
            userEmail: '',
            isAdmin: false
        }
    },
    getters: {
        userInfo: (state) => {
            return {
                'name': state.userName,
                'email': state.userEmail
            }
        }
    },
    actions: {
        async getUsersInfo() {
            const auth = useAuthStore()
            const uri = `${auth.baseUrl}/users`
            const request = auth.buildRequest('GET', true);
            const rawResponse = await fetch(uri, request)
            const response = await rawResponse.json();
            if (rawResponse.status != 200) {
                return {
                    'error': rawResponse.status
                }
            }
            return response;

        },
        async getInfoUser() {
            const auth = useAuthStore()
            const uri = `${auth.baseUrl}/users/${auth.userId}`
            const request = auth.buildRequest('GET', true);
            const rawResponse = await fetch(uri, request)
            if (rawResponse.status == 200) {
                const response = await rawResponse.json()
                this.userName = response.name
                this.userEmail = response.email,
                    this.isAdmin = request.isAdmin

                return true
            }
            return false

        },
        async addUser(username, email, password) {
            const auth = useAuthStore()
            const uri = `${auth.baseUrl}/users`
            const request = auth.buildRequest('POST', true, {
                'email': email,
                'username': username,
                'password': password
            })
            const rawResponse = await fetch(uri, request)
            if (rawResponse.ok == false) {
                return false
            }
            else {
                return true
            }
        },
        async resetUserPass(userId, password) {
            const auth = useAuthStore()
            const uri = `${auth.baseUrl}/users/${userId}/${password}}`
            const request = auth.buildRequest('PUT', true)
            const rawResponse = await fetch(uri, request)
            if (rawResponse.ok == false) {
                return false
            }
            else {
                return true
            }
        },
        async updateUser(userId, userData) {
            const auth = useAuthStore()
            const uri = `${auth.baseUrl}/users/${userId}`
            const request = auth.buildRequest('PUT', true, {
                'email': userData.email,
                'username': userData.username,
                'isAdmin': userData.isAdmin ? userData.isAdmin : false,
                'password': userData.password
            })
            const response = await fetch(uri, request)
            return response.status == 200
        },
        async addGroups(userId, groups) {
            const auth = useAuthStore()
            const uri = `${auth.baseUrl}/groups/user/${userId}/`
            const request = auth.buildRequest('POST', true, groups)
            const rawResponse = await fetch(uri, request)
            return rawResponse.status == 200
        },
        async getGroups(userId) {
            const auth = useAuthStore()
            const uri = `${auth.baseUrl}/groups/user/${userId}/`
            const request = auth.buildRequest('GET', true)
            const rawResponse = await fetch(uri, request)
            if (rawResponse.ok == false) {
                return false
            }
            else {
                return true
            }
        },
        async deleteGroups(userId, groups) {
            const auth = useAuthStore()
            const uri = `${auth.baseUrl}/groups/user/${userId}`
            const request = auth.buildRequest('DELETE', true, groups)
            const rawResponse = await fetch(uri, request)
            if (rawResponse.ok == false) {
                return false
            }
            else {
                return true
            }
        },
        async getImageUser(userId) {
            const auth = useAuthStore()
            const uri = `${auth.baseUrl}/image/user/${userId}`
            const request = auth.buildRequest('GET', false)
            const rawResponse = await fetch(uri, request)
            return rawResponse.status == 200 ? await rawResponse.json() : false
        },
        getImageUser2(userId) {
            const auth = useAuthStore()
            const uri = `${auth.baseUrl}/image/user/${userId}`
            const request = auth.buildRequest('GET', false)
            return fetch(uri, request)            
        }
    }
})