<template>
    <div class="grid grid-cols-3">
        <div class="col-span-2">
            <GMapMap ref="gMapObj" style="width: 100%; height: 44rem;" :zoom="zoomLevel" map-type-id="terrain"
                :center="lastLocation" :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: true,
                    fullscreenControl: false,
                }">
                <GMapMarker v-for="(item, idx) in props.markers"
                    :position="{ lng: item.location.lng, lat: item.location.lat, trakdId: item.trackId }"
                    :icon="setIcon(idx)">
                </GMapMarker>
            </GMapMap>
        </div>
        <div class="relative">
            <div style="min-height: 250px;" class="ml-3 border rounded-md">
                <img :src="imageSrc" class="mt-1" />
            </div>
            <div style="min-height: 100px;" class="ml-3 border rounded-md">
                <img :src="imageSrcPlate" class="mt-1" />
            </div>
            <div class="w-full relative">
                <div class="">
                    <div class="px-2 py-2 mt-3 ml-3 border rounded-md" v-for="(marker, idx) in markers" :key="idx"
                        v-show="idx == activeIdx">
                        <p class="dark:text-white ">
                            <label class="inline-block w-1/3">No. Reg.</label>
                            {{ idx + 1 }}
                        </p>
                        <p class="dark:text-white ">
                            <label class="inline-block w-1/3">Fecha</label>
                            {{ new Date(marker.logDate).toLocaleDateString() }}
                        </p>
                        <p class="dark:text-white">
                            <label class="inline-block w-1/3">Hora</label>
                            {{ new Date(marker.logDate).toLocaleTimeString() }}
                        </p>
                        <p class="dark:text-white">
                            <label class="inline-block w-1/3">Camara</label>
                            {{ marker.cameraName }}
                        </p>
                        <p class="dark:text-white">
                            <label class="inline-block w-1/3">Poblaci&oacute;n</label>
                            {{ marker.groupName }}
                        </p>
                    </div>
                </div>
                <div class="w-full grid grid-cols-2 px-2 ml-1 mt-3">
                    <div class="w-full">
                        <button @click="moveLeft"
                            class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                            <Icon class="inline-flex" icon="mingcute:left-fill"></Icon>
                            Anterior
                        </button>
                    </div>
                    <div class="w-full">
                        <button @click="moveRight"
                            class="w-full ml-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                            Siguiente
                            <Icon class="inline-flex" icon="mingcute:right-fill"></Icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { Icon } from '@iconify/vue';
import { ref, defineProps, onMounted, mergeProps, watch } from 'vue'
import { uuid } from 'vue-uuid'
import { useImageStore } from '@/store/image';
const imageSrc = ref()
const imageSrcPlate = ref()
const activeIdx = ref(0)
const gMapObj = ref()
const mapViewerId = ref('')
const props = defineProps({
    markers: Array,
    lastLocation: {
        type: Object,
        default: {
            lng: 0,
            lat: 0
        }
    },
    zoomLevel: {
        type: Number,
        default: 17
    },
    trackId: String,
    clearData: Boolean,
})
const moveLeft = () => {
    if (activeIdx.value <= 0) {
        activeIdx.value = props.markers.length - 1
    }
    else {
        activeIdx.value--
    }
    getImageVehicle(props.markers[activeIdx.value].trackId)
}
const moveRight = () => {
    if (activeIdx.value >= props.markers.length - 1) {
        activeIdx.value = 0
    }
    else {
        activeIdx.value++
    }

    getImageVehicle(props.markers[activeIdx.value].trackId)
}


const getImageVehicle = async (trackId) => {
    const imageStore = useImageStore()
    const image = await imageStore.getVehicleImage(trackId)
    if (image) {
        imageSrc.value = 'data:image/jpeg;base64, ' + image.image
    }
    else imageSrc.value = ''
    const plateImage = await imageStore.getPlateImage(trackId)
    if (plateImage) {
        imageSrcPlate.value = 'data:image/jpeg;base64,' + plateImage.image
    }
    else
        imageSrcPlate.value = ''
}

const setIcon = (idx) => {
    if (idx != activeIdx.value)
        return {
            url: require('@/assets/map-marker.svg'),
            scaledSize: { width: 50, height: 50 },
            labelOrigin:
            {
                x: 16,
                y: -10
            }
        }
    return {
        url: require('@/assets/car-marker.svg'),
        scaledSize: { width: 50, height: 50 },
        labelOrigin:
        {
            x: 16,
            y: -10
        }
    }

}
const toHome = async () => {
    let map = await gMapObj.value.$mapPromise
    map.setCenter(props.lastLocation)
    map.setZoom(props.zoomLevel)
}
onMounted(async () => {
    mapViewerId.value = 'mapViewerId-' + uuid.v1()
})
watch(activeIdx, async (nIdx, oIdx) => {
    let map = await gMapObj.value.$mapPromise
    let position = props.markers[nIdx]
})

const fitMapBounds = () => {
    let markersFit = []
    let bounds = new google.maps.LatLngBounds()
    props.markers.forEach((mark) => {
        markersFit.push({
            position: {
                lng: mark.location.lng, lat: mark.location.lat
            }
        })
        bounds.extend({
            lng: mark.location.lng, lat: mark.location.lat
        })
    })

    if (markersFit.length > 0) {
        gMapObj.value.$mapPromise.then((theMap) => {
            theMap.fitBounds(bounds);
        })
    }
}
watch(() => props.markers,
    (markers) => {
        imageSrc.value = ''
        fitMapBounds()
    })
watch(props.clearData, (nValue, oValue) => {
    if (nValue) {
        imageSrc.value = ''
    }
})
</script>
<style>
.active-marker {
    color: blueviolet;
    background-color: purple;
}
</style>