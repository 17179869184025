import Tables from '@/components/Tables.vue';

<template>
  <div>
    <Tables />
  </div>
</template>

<script>
  import Tables from "@/components/Tables.vue";
  export default {
    components: {
      Tables,
    },
  };
</script>
