import { defineStore } from 'pinia'
import { useAuthStore } from "./auth"
import { uuid } from 'vue-uuid'
export const useGroupStore = defineStore('groups', {
    actions: {
        async getGroups() {
            const auth = useAuthStore()
            const url = auth.isAdmin ? '/groups' : '/groups/user';
            const request = auth.buildRequest('GET', true)
            const rawResponse = await fetch(auth.baseUrl + url, request)
            if (rawResponse.status == 200) {
                const response = await rawResponse.json()
                return response
            }
            return false
        },
        async getUserGroups(id)  {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const rawResponse = await fetch(auth.baseUrl + '/groups/user/' + (id ? `${id}`: ''), request)
            return rawResponse.status == 200 ? await rawResponse.json() : false
        },
        async addGroup(name) {
            const auth = useAuthStore()
            const request = auth.buildRequest('POST', true, name)
            const id = uuid.v1()
            const rawResponse = await fetch(auth.baseUrl + '/groups/' + id, request)
            if (rawResponse.status == 200) {
                return id
            }
            return false
        },
        async deleteGroup(id) {
            const auth = useAuthStore()
            const request = auth.buildRequest('DELETE', true)
            const rawResponse = await fetch(auth.baseUrl + '/groups/' + id, request)
            if (rawResponse.status == 200) {
                return true
            }
            return false
        },
        async editGroup(id, name) {
            const auth = useAuthStore()
            const request = auth.buildRequest('PUT', true, name)
            const rawResponse = await fetch(auth.baseUrl + '/groups/' + id, request)
            if (rawResponse.status == 200) {
                return id
            }
            return false
        },
        async getGroupUsers(groupId) {
            const auth = useAuthStore()
            const request = auth.buildRequest('GET', true)
            const rawResponse = await fetch(auth.baseUrl + '/groups/' + groupId, request)
            
            return rawResponse.status == 200 ? await rawResponse.json() : false
        }
    }
})