<template>
    <div class="overlay">
    <div
        class="spinner inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status">
        <span
          class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Loading...</span>
      </div>

  </div>
</template>
<style scoped>
.overlay {
  position: relative;
  display: block;  
  z-index: 99;
  border-radius: 15px;
}

.overlay:before {
  content: "";
  width: 100vw;
  height: 100vw;
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
}
.spinner {
  z-index: 99;
  position: fixed;
  left: 50%;
  margin-top: 23vw;
  padding: 40px;
  color: white;
}
</style>
<script setup>
</script>