<template>
  <div class="dashboard p-4">
    <div class="bg-white dark:bg-gray-800 p-5 lg:mt-0 mt-4  border dark:border-gray-700 rounded-md w-full">
      <span class="text-lg font-semibold dark:text-gray-200"> Usuarios </span>
      <div style="position: relative; float: right;">
        <Modal :title="modalTitle" subtitle="" btnTextSubmit="Ok" btnText="Nuevo usuario" ref="userModal"
          @click="clearUserData" @submit="createOrEditUser">
          <template v-slot:body>
            <div class="space-y-5 pb-5">
              <div class="space-y-3">
                <p>Nombre</p>
                <input type="text" placeholder="" v-model="newUserName"
                  class="p-2 border dark:border-gray-600 dark:bg-gray-700 w-full rounded outline-none" />
              </div>
              <div class="space-y-3">
                <p>Email</p>
                <input type="text" placeholder="" v-model="newUserEmail"
                  class="p-2 border dark:border-gray-600 dark:bg-gray-700 w-full rounded outline-none" />
              </div>
              <div class="space-y-3">
                <p>Password</p>
                <input type="text" placeholder="" v-model="newUserPass"
                  class="p-2 border dark:border-gray-600 dark:bg-gray-700 w-full rounded outline-none" />
              </div>
            </div>
            <div v-show="userId != ''"
              class="dark:bg-gray-800 dark:hover:bg-gray-700 border dark:border-gray-700 max-w-lg px-4 py-3 rounded-md text-gray-500 dark:text-gray-400"
              style="min-width: 100%;">
              <span class="dark:text-gray-200 pr-3">Poblaci&oacute;n: </span>
              <span v-for="(item,idx) in userGroups" :key="item.id"
                class="bg-green-600 rounded-full py-1 px-2 mr-2 text-white inline-flex items-center justify-center">
                {{ item.name }}       
              </span>
            </div>
          </template>
        </Modal>
      </div>
      <div class="ps divide-y h-96 mt-5 dark:divide-gray-700 ps--active-y">
        <UserItem v-for="(item, id) in users" :key="id" 
          :id="item.id" :name="item.name" :email="item.email"
          :modalUser="userModal" :groups="userGroups" />
      </div>
    </div>
  </div>
</template>
<script setup>
import UserItem from '@/components/UserItem.vue';
import { useUsersStore } from '@/store/user';
import Modal from '@/components/modal.vue';
import { onMounted, ref, provide, watch } from 'vue';
import { useGroupStore } from '@/store/group';

const userGroups = ref([])
const users = ref([])
const userModal = ref(null)
const newUserEmail = ref('')
const newUserName = ref('')
const newUserPass = ref('')
const userId = ref('')

const modalTitle = ref('Añadir usuario')
const createUser = async () => {
  const userStore = useUsersStore()
  const response = await userStore.addUser(newUserName.value, newUserEmail.value, newUserPass.value)
  if (response) {
    users.value = await userStore.getUsersInfo()
    return true
  }
  return false
}
const editUser = async () => {
  const userStore = useUsersStore()
  const response = await userStore.updateUser(userId.value, {
    'email': newUserEmail.value,
    'username': newUserName.value,
    'password': newUserPass.value
  })
  return response
}
const clearUserData = () => {
  newUserEmail.value = ''
  newUserName.value = ''
  userId.value = ''
  newUserPass.value = ''
  modalTitle.value = 'Añadir usuario'
}
const createOrEditUser = () => {
  userModal.value.isOpen = userId.value == '' ? !createUser() : !editUser()
}
const getUserGroups = async () => {
    const groupStore = useGroupStore()
    const response = await groupStore.getUserGroups(userId.value)
    if (response !== false) {        
        userGroups.value = response
    }    
}
onMounted(async () => {
  const userStore = useUsersStore()
  users.value = await userStore.getUsersInfo()
})
watch(userId, async (nId, oId) => {
  getUserGroups(nId)  
})
provide('userData', { newUserEmail, newUserName, userId, newUserPass, modalTitle, userGroups })
</script>
<style scoped>
th {
  background: #036;
  color: #fff;
  padding: .5em .75em;
  text-align: start;
}

td {
  padding-left: 2rem;
}
</style>